$primary-color: #eb4abc;
$secondary-color: #cbf73f;
$dark-color: #1b1b1b;
$light-color: #f4f5ef;

.blog-settings-category {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  padding: 2rem;
  margin-bottom: 2rem;
  transition: all 0.3s ease;

  &:hover {
    border-color: rgba($secondary-color, 0.2);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  &-title {
    font-size: 1.6rem;
    font-weight: 600;
    color: $light-color;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba($secondary-color, 0.2);
    position: relative;
    background: linear-gradient(45deg, $light-color, rgba($primary-color, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 60px;
      height: 2px;
      background: $secondary-color;
      box-shadow: 0 0 10px rgba($secondary-color, 0.5);
    }
  }

  .blog-settings-blogs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2rem;
  }

  .blog-settings-blog {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    overflow: hidden;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-4px);
      border-color: rgba($secondary-color, 0.3);
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);

      .blog-settings-blog-image img {
        transform: scale(1.05);
      }
    }

    &-image {
      position: relative;
      width: 100%;
      height: 200px;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;
      }
    }

    &-content {
      padding: 1.5rem;
      position: relative;

      &-title {
        font-size: 1.3rem;
        color: $light-color;
        margin-bottom: 0.75rem;
        font-weight: 600;
      }

      &-text {
        color: rgba($light-color, 0.7);
        margin-bottom: 0.5rem;
        font-size: 0.95rem;
        line-height: 1.5;

        &:last-of-type {
          font-size: 0.85rem;
          color: rgba($secondary-color, 0.8);
          margin-bottom: 0;
          display: inline-block;
          margin-right: 1rem;
        }
      }

      &-actions {
        display: grid;
        grid-template-columns: repeat(3, minmax(min-content, max-content));
        gap: 0.4rem;
        vertical-align: middle;
        margin-top: 1rem;

        .action-button {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          cursor: pointer;
          transition: all 0.3s ease;
          background: rgba(255, 255, 255, 0.05);
          backdrop-filter: blur(10px);

          svg {
            font-size: 0.8rem;
            transition: all 0.3s ease;
          }

          &.preview {
            color: $secondary-color;
            &:hover {
              background: rgba($secondary-color, 0.1);
              border-color: rgba($secondary-color, 0.3);
              transform: translateY(-2px);
              box-shadow: 0 4px 12px rgba($secondary-color, 0.2);
            }
          }

          &.edit {
            color: $primary-color;
            &:hover {
              background: rgba($primary-color, 0.1);
              border-color: rgba($primary-color, 0.3);
              transform: translateY(-2px);
              box-shadow: 0 4px 12px rgba($primary-color, 0.2);
            }
          }

          &.delete {
            color: #ff4757;
            &:hover {
              background: rgba(#ff4757, 0.1);
              border-color: rgba(#ff4757, 0.3);
              transform: translateY(-2px);
              box-shadow: 0 4px 12px rgba(#ff4757, 0.2);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .blog-settings-category {
    padding: 1rem;

    &-title {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
    }

    .blog-settings-blogs {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }

    .blog-settings-blog-content {
      &-actions {
        position: static;
        margin-top: 1rem;
        justify-content: flex-start;
        gap: 0.5rem;

        .action-button {
          width: 38px;
          height: 38px;

          svg {
            font-size: 1rem;
          }
        }
      }

      &-link {
        padding: 0.6rem 0.8rem;
        font-size: 0.85rem;
        min-width: 80px;
        max-width: none;

        svg {
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .blog-settings-category {
    .blog-settings-blog-content {
      &-actions {
        flex-direction: column;
        width: 100%;
      }

      &-link {
        max-width: none;
        width: 100%;
      }
    }
  }
}
