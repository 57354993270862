@import "../../styles/variables";

.custom-toast {
  position: fixed;
  top: 6rem;
  left: 50%;
  transform: translateX(-50%) translateX(120%);
  min-width: 300px;
  max-width: 400px;
  background: rgba($dark-color, 0.95);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $light-color;
  z-index: 10000;
  transform: translateX(120%);
  transition: transform 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &.show {
    transform: translateX(-50%);
  }

  &.hide {
    transform: translateX(-50%) translateX(120%);
  }

  .toast-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 1.2rem;
  }

  .toast-message {
    flex-grow: 1;
    font-size: 0.95rem;
  }

  .toast-close {
    background: none;
    border: none;
    color: rgba($light-color, 0.5);
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    &:hover {
      color: $light-color;
      transform: scale(1.1);
    }
  }

  .toast-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background: linear-gradient(
      to right,
      rgba($secondary-color, 0.5),
      rgba($primary-color, 0.5)
    );
    animation: progress linear forwards;
    border-bottom-left-radius: 12px;
  }

  // Toast types
  &.success {
    border-left: 3px solid $secondary-color;
    .toast-icon {
      color: $secondary-color;
    }
  }

  &.error {
    border-left: 3px solid #ff4545;
    .toast-icon {
      color: #ff4545;
    }
  }

  &.loading {
    border-left: 3px solid $primary-color;
    .toast-icon {
      color: $primary-color;
    }
    .spinning {
      animation: spin 1s linear infinite;
    }
  }

  &.info {
    border-left: 3px solid #3498db;
    .toast-icon {
      color: #3498db;
    }
  }

  // Additional toast types
  &.warning {
    border-left: 3px solid #f1c40f;
    .toast-icon {
      color: #f1c40f;
    }
  }

  // Toast states
  &.persistent {
    .toast-progress {
      display: none;
    }
  }

  &.with-action {
    padding-right: 0;

    .toast-action {
      padding: 0.5rem 1rem;
      margin: -1rem -1rem -1rem 0.5rem;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      color: $secondary-color;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: rgba($secondary-color, 0.1);
      }
    }
  }
}

@keyframes progress {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Handle multiple toasts
.custom-toast + .custom-toast {
  top: calc(5rem + 4.5rem);
}

.custom-toast + .custom-toast + .custom-toast {
  top: calc(5rem + 9rem);
}

// Add more if needed for more simultaneous toasts
