//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;

.footer-navigation {
  background-color: $dark;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 30;
  color: white;
  font-size: 0.7rem;
  z-index: 99;
  border-top: 1px solide #d3d3d3;

  &-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.65rem 0.85rem;
    gap: 0.25rem;

    &--active {
      svg {
        fill: #cbf73f !important;
      }
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
      background-color: #363636;
      svg {
        fill: #838383 !important;
      }

      p {
        color: #838383;
      }
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: #fff;

      &-team {
        width: 2.1rem;
      }
    }

    &-title {
      justify-self: center;
      align-self: center;
    }
  }
}
