//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.single-blog {
  padding: 1rem;
  background-color: $white;

  a {
    text-decoration: underline;
  }

  svg {
    cursor: pointer;
  }

  &-image {
    padding-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;

    @include mq(midsmall) {
      max-height: 25rem;
    }
  }

  &-title {
    font-weight: 800;
    font-size: 1.25rem;
    text-align: center;

    padding-top: 2.5rem;
    @include mq(welldone) {
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }

  &-sub {
    font-weight: 800;
    padding-bottom: 0.5rem;
    text-align: center;
    padding-bottom: 1rem;
    @include mq(welldone) {
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }

  &-text {
    padding-bottom: 1rem;
    ul {
      list-style-type: disc;
      padding-left: 1rem; // Adjust as needed
    }

    ol {
      list-style-type: decimal;
      padding-left: 1rem; // Adjust as needed
    }
    @include mq(welldone) {
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }

  &-buttons {
    padding-bottom: 1rem;
    @include mq(welldone) {
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }

  &-link {
    text-decoration: underline;
    @include mq(welldone) {
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }

  &-list {
    list-style-type: disc;
    padding: 1rem;
    @include mq(welldone) {
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }

  &-pdf {
    display: none;
    @include mq(welldone) {
      display: block;
    }
  }

  &-images {
    @include mq(welldone) {
      display: none;
    }
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.single-blog-text p {
  word-wrap: break-word;
}
