$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;

.app-navigation {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  gap: 0.85rem;
  text-align: center;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  position: sticky;
  z-index: 30;
  top: 0;

  &-top {
    display: flex;
    align-items: center;
    flex: 1 1 100%;
  }

  &-logo {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
  }

  &-avatar,
  &-burger {
    width: 42px;
    height: 42px;
  }

  &-avatar {
    position: relative;
    display: flex;
    background-color: #fff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &-days {
    margin-top: 0.25rem;
    &-text {
      font-weight: 400;
    }
  }

  &-burger {
    svg {
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }

  &-date {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    svg {
      cursor: pointer;
      stroke: $green;
    }
  }

  &-avatar {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }

  &-ranking {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    &-switch {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-content: center;
      height: 2.5rem;
      padding: 0 0.25rem;

      background-color: white;
      border-radius: 1.25rem;
      @include mq(welldone) {
        margin-bottom: 1rem;
      }

      button {
        border: none;
        text-decoration: none;
        background: none;
        width: 100%;
        height: 2rem;
        font-weight: 800;
        border-radius: 1.25rem;
        padding: 0;
        color: #000;
      }

      .switch {
        background-color: $dark;
        color: #fff;
      }
    }

    &-search {
      display: flex;
      align-items: stretch;
      background-color: #fff;
      border: 1px solid #d3d3d3;
      border-radius: 2px;

      &-loupe {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 2rem;
      }

      &-input {
        height: 100%;
        border-radius: 0.2rem;
        border: none;
        padding: 0.65rem;
        display: flex;
        min-width: 0;
        flex: 1 1 100%;
      }
    }
  }
}
.team-header {
  height: 8rem !important;
}
