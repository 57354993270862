.countdown {
  $self: &;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif;

  &__units {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
    margin: 0 auto;
    padding: 0.15rem;
    width: fit-content;

    &.habits-units {
      justify-content: flex-end;
    }

    // Large version (dashboard/home)
    &.large-version {
      gap: 2rem;

      #{$self}__number {
        font-size: 12rem !important;
        @media (min-width: 1024px) {
          font-size: 8rem !important;
        }
      }
    }

    &.hide-days {
      grid-template-columns: repeat(3, 1fr) !important;
      #{$self}__unit:first-child {
        // display: none;
      }
    }
  }

  &__unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    text-align: center;

    .habits & {
      min-width: 2rem;
    }
  }

  &__number {
    font-family: "Montserrat", sans-serif !important;
    font-size: 2.5rem !important;
    font-weight: 800 !important;
    line-height: 1 !important;
    color: #000 !important;
    text-align: center !important;
    margin: 0 !important;
    padding: 0 !important;

    &.with-colon {
      font-size: 1.5rem !important;
    }

    &_small {
      font-size: 3rem !important;
    }
  }

  &__label {
    margin-top: 0.25rem;
    font-size: 0.8rem;
  }

  &.habits,
  &.weeklie {
    width: auto;
    display: inline-flex;
    margin: 0 auto;

    #{$self}__units {
      gap: 0.1rem;
      padding: 0.1rem;
      justify-content: flex-end;
    }

    #{$self}__number {
      color: #000;
      font-size: 0.9rem !important;
    }

    #{$self}__unit {
      min-width: auto;
      padding: 0 0.1rem;
    }
  }
}
