$primary-color: #eb4abc;
$secondary-color: #cbf73f;
$dark-color: #1b1b1b;
$light-color: #f4f5ef;

.challenge-settings {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  color: $light-color;

  .settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      inset: -1px;
      background: linear-gradient(
        45deg,
        transparent,
        rgba($primary-color, 0.2),
        rgba($secondary-color, 0.2),
        transparent
      );
      z-index: 0;
      animation: borderGlow 3s linear infinite;
    }

    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0;
      position: relative;
      z-index: 1;
      background: linear-gradient(
        45deg,
        $light-color,
        rgba($primary-color, 0.8)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .create-button {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.9rem 1.8rem;
      background: $primary-color;
      border: 1px solid rgba($secondary-color, 0.3);
      border-radius: 12px;
      color: $light-color;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      z-index: 1;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba($primary-color, 0.3),
          0 0 0 2px rgba($secondary-color, 0.1) inset;
      }
    }

    .header-actions {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .global-settings-button {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.9rem 1.8rem;
      background: rgba($secondary-color, 0.1);
      border: 1px solid rgba($secondary-color, 0.3);
      border-radius: 12px;
      color: $light-color;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        background: rgba($secondary-color, 0.15);
        box-shadow: 0 4px 12px rgba($secondary-color, 0.2);
      }

      svg {
        font-size: 1.2rem;
        color: $secondary-color;
      }
    }
  }

  .challenge-form {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    margin-bottom: 2rem;
    overflow: hidden;
    animation: slideDown 0.3s ease forwards;

    .form-header {
      padding: 1.5rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
      background: rgba(255, 255, 255, 0.02);

      h3 {
        margin: 0;
        font-size: 1.2rem;
        color: $light-color;
      }
    }

    .form-content {
      padding: 1.5rem;
      display: grid;
      gap: 1.5rem;
    }

    .form-row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 1rem;
    }

    .form-group {
      display: grid;
      gap: 0.5rem;

      label {
        font-size: 0.9rem;
        color: rgba($light-color, 0.8);
      }

      input {
        width: 100%;
        padding: 0.9rem 1rem;
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        color: $light-color;
        font-size: 0.95rem;
        transition: all 0.3s ease;

        &:focus {
          outline: none;
          border-color: rgba($primary-color, 0.5);
          box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
          background: rgba(255, 255, 255, 0.05);
        }
      }

      // Special styling for date input
      input[type="date"] {
        position: relative;
        padding-right: 2.5rem; // Space for the calendar icon
        appearance: none;
        -webkit-appearance: none;
        background: rgba(255, 255, 255, 0.03);

        // Remove default calendar icon and add custom one
        &::-webkit-calendar-picker-indicator {
          position: absolute;
          right: 0.75rem;
          top: 50%;
          transform: translateY(-50%);
          width: 1.2rem;
          height: 1.2rem;
          background: none;
          cursor: pointer;

          &::after {
            content: "📅";
            font-size: 1.2rem;
            opacity: 0.7;
          }
        }

        // Style the date text
        &::-webkit-datetime-edit {
          color: $light-color;
          padding: 0;
        }

        &::-webkit-datetime-edit-fields-wrapper {
          padding: 0;
        }

        &::-webkit-datetime-edit-text {
          padding: 0 0.2em;
          color: rgba($light-color, 0.7);
        }

        &::-webkit-datetime-edit-month-field,
        &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit-year-field {
          color: $light-color;
        }

        &::-webkit-inner-spin-button {
          display: none;
        }
      }

      // Style for the date display in challenge details
      .detail {
        .date {
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem 0.75rem;
          background: rgba(255, 255, 255, 0.03);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          font-family: monospace;
          letter-spacing: 0.5px;
          color: $light-color;
          font-size: 0.9rem;

          svg {
            color: $secondary-color;
            font-size: 1rem;
          }
        }
      }

      .global-settings-toggle {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        width: 100%;
        padding: 1rem;
        background: rgba($secondary-color, 0.1);
        border: 1px solid rgba($secondary-color, 0.3);
        border-radius: 8px;
        color: $light-color;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease;

        svg {
          color: $secondary-color;
          font-size: 1.2rem;
        }

        &:hover {
          background: rgba($secondary-color, 0.15);
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba($secondary-color, 0.2);
        }
      }
    }

    .image-upload {
      input[type="file"] {
        display: none;
      }

      .upload-label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.9rem 1rem;
        background: rgba($primary-color, 0.1);
        border: 2px dashed rgba($secondary-color, 0.3);
        border-radius: 8px;
        color: $light-color;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background: rgba($primary-color, 0.15);
          border-color: rgba($secondary-color, 0.5);
        }
      }

      .image-preview {
        margin-top: 1rem;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid rgba($secondary-color, 0.2);

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }

    .form-actions {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.9rem 1.8rem;
        border-radius: 8px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease;

        &.save-button {
          background: $primary-color;
          color: $light-color;
          border: 1px solid rgba($secondary-color, 0.3);

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba($primary-color, 0.3),
              0 0 0 2px rgba($secondary-color, 0.1) inset;
          }
        }

        &.cancel-button {
          background: rgba(255, 255, 255, 0.05);
          color: $light-color;
          border: 1px solid rgba(255, 255, 255, 0.1);

          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }

  .challenges-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }

  .challenge-card {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    overflow: hidden;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    }

    .challenge-image {
      position: relative;
      aspect-ratio: 16/9;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .challenge-status {
        position: absolute;
        top: 1rem;
        right: 1rem;

        .status {
          padding: 0.5rem 1rem;
          border-radius: 20px;
          font-size: 0.8rem;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.5px;

          &.active {
            background: rgba($secondary-color, 0.9);
            color: $dark-color;
          }

          &.inactive {
            background: rgba(255, 255, 255, 0.2);
            color: $light-color;
          }

          &.ended {
            background: rgba($primary-color, 0.9);
            color: $light-color;
          }
        }
      }
    }

    .challenge-content {
      padding: 1.5rem;

      h3 {
        margin: 0 0 1rem;
        font-size: 1.2rem;
        color: $light-color;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status-badge {
          font-size: 0.8rem;
          padding: 0.5rem 1rem;
          border-radius: 20px;
          display: flex;
          align-items: center;
          gap: 0.5rem;

          &.active {
            background: rgba($secondary-color, 0.9);
            color: $dark-color;
          }

          &.inactive {
            background: rgba($primary-color, 0.2);
            color: $primary-color;
          }

          &.ended {
            background: rgba($primary-color, 0.9);
            color: $light-color;
          }

          .status-icon {
            font-size: 1rem;
          }
        }
      }

      .challenge-details {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        gap: 1rem;
        margin-bottom: 1.5rem;
        padding: 1rem;
        background: rgba(255, 255, 255, 0.03);
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.07);

        .detail {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          color: rgba($light-color, 0.7);
          font-size: 0.9rem;

          svg {
            color: $secondary-color;
            font-size: 1.1rem;
            flex-shrink: 0;
          }

          .date {
            font-family: monospace;
            letter-spacing: 0.5px;
            background: rgba(255, 255, 255, 0.03);
            padding: 0.25rem 0.5rem;
            border-radius: 4px;
            border: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
      }

      .challenge-actions {
        display: flex;
        gap: 0.75rem;
        justify-content: flex-end;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 44px;
          height: 44px;
          border-radius: 12px;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;

          svg {
            font-size: 1.2rem;
          }

          &::before {
            content: attr(data-tooltip);
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0.5rem 0.75rem;
            border-radius: 6px;
            background: rgba($dark-color, 0.9);
            color: $light-color;
            font-size: 0.8rem;
            white-space: nowrap;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s ease;
          }

          &:hover {
            transform: translateY(-2px);

            &::before {
              opacity: 1;
              top: -45px;
            }
          }

          &.edit-button {
            background: rgba($secondary-color, 0.1);
            color: $secondary-color;

            &:hover {
              background: rgba($secondary-color, 0.2);
              box-shadow: 0 4px 12px rgba($secondary-color, 0.2);
            }
          }

          &.toggle-button {
            background: rgba($primary-color, 0.1);
            color: $primary-color;
            width: auto;
            padding: 0 1.5rem;
            gap: 0.75rem;
            font-weight: 500;
            position: relative;
            overflow: hidden;

            &.active {
              background: rgba($secondary-color, 0.15);
              color: $secondary-color;
              border: 1px solid rgba($secondary-color, 0.3);

              &:hover {
                background: rgba($secondary-color, 0.2);
                box-shadow: 0 4px 12px rgba($secondary-color, 0.2);
              }
            }

            svg {
              font-size: 1.4rem;
              transition: all 0.3s ease;
            }

            &:hover {
              transform: translateY(-2px);
            }
          }

          &.delete-button {
            background: rgba(255, 69, 58, 0.1);
            color: rgb(255, 69, 58);

            &:hover {
              background: rgba(255, 69, 58, 0.2);
              box-shadow: 0 4px 12px rgba(255, 69, 58, 0.2);
            }
          }
        }
      }
    }
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes borderGlow {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    padding: 1rem;

    .settings-header {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
      padding: 1.2rem;

      h2 {
        font-size: 1.5rem;
      }
    }

    .challenge-form {
      .form-row {
        grid-template-columns: 1fr;
      }
    }

    .challenges-grid {
      grid-template-columns: 1fr;
    }

    .challenge-card {
      .challenge-content {
        padding: 1rem;

        .challenge-details {
          grid-template-columns: 1fr;
          padding: 0.75rem;

          .detail {
            font-size: 0.85rem;
          }
        }

        .challenge-actions {
          flex-wrap: wrap;
          justify-content: center;

          button {
            flex: 1;
            min-width: 44px;
            max-width: 120px;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .challenge-settings {
      padding: 0.5rem;

      .challenge-card {
        margin-bottom: 1rem;

        .challenge-image {
          aspect-ratio: auto;
          max-height: 200px;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .challenge-content {
          padding: 1rem;

          .challenge-details {
            grid-template-columns: 1fr;
            padding: 0.75rem;
            gap: 0.75rem;
          }

          .challenge-actions {
            flex-wrap: wrap;
            gap: 0.5rem;

            button {
              flex: 1;
              min-width: auto;
              padding: 0.75rem;
              font-size: 0.9rem;

              &.toggle-button {
                flex: 2;
                min-width: 120px;
              }
            }
          }
        }
      }
    }
  }
}

.logo-upload {
  img {
    width: 10rem;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.date-picker-input {
  width: 100%;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0.9rem 1rem;
  color: #f4f5ef;
  font-size: 0.95rem;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: rgba($primary-color, 0.5);
    background: rgba(255, 255, 255, 0.05);
  }
}

// Style the datepicker popup
.react-datepicker {
  background-color: #1b1b1b !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  font-family: inherit;

  &__header {
    background-color: rgba(255, 255, 255, 0.03) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }

  &__current-month,
  &__day-name {
    color: #f4f5ef !important;
  }

  &__day {
    color: #f4f5ef !important;

    &:hover {
      background-color: rgba($secondary-color, 0.2) !important;
    }

    &--selected {
      background-color: $primary-color !important;
      color: #f4f5ef !important;
    }

    &--keyboard-selected {
      background-color: rgba($primary-color, 0.5) !important;
    }
  }

  &__navigation-icon {
    &::before {
      border-color: #f4f5ef !important;
    }
  }

  &__triangle {
    &::before,
    &::after {
      border-bottom-color: #1b1b1b !important;
    }
  }
}
