//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;

.score {
  position: relative;

  &-chart {
    display: flex;
    position: relative;
    overflow: hidden;
  }

  &-circle {
    position: relative;
  }

  &-progress {
    position: relative;

    &-background {
      fill: none;
      stroke: #d3d3d3;
      stroke-width: 0.4;
      z-index: 1;
    }

    &-bar {
      fill: #fff;
      stroke-width: 2.8;
      animation: progress 1s ease-out forwards;
      z-index: 4;
      stroke: $green;

      &.placeholder {
        fill: transparent;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;

      &.placeholder {
        color: #d3d3d3;
      }
    }
  }
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.text-75xl {
  font-size: 4rem; /* Adjust this value as needed, between the sizes of 7xl and 8xl */
  line-height: 3.5rem;
}
