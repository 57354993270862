@import "../../styles/variables";

.admin-welcome {
  text-align: center;
  padding: 4rem 2rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(20px);
  margin-top: 2rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
        circle at 0% 0%,
        rgba($primary-color, 0.1) 0%,
        transparent 50%
      ),
      radial-gradient(
        circle at 100% 100%,
        rgba($secondary-color, 0.1) 0%,
        transparent 50%
      );
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    inset: -2px;
    background: linear-gradient(
      45deg,
      transparent,
      rgba($primary-color, 0.3),
      rgba($secondary-color, 0.3),
      transparent
    );
    z-index: -2;
    animation: borderRotate 4s linear infinite;
  }

  h2 {
    font-size: 2.8rem;
    color: $light-color;
    margin-bottom: 1.5rem;
    font-weight: 700;
    position: relative;
    display: inline-block;
    background: linear-gradient(45deg, $light-color, rgba($primary-color, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 30px rgba($primary-color, 0.3);

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 3px;
      background: $primary-color;
      border-radius: 2px;
      box-shadow: 0 0 10px rgba($primary-color, 0.5);
    }
  }

  p {
    color: rgba($light-color, 0.7);
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;

    span {
      color: $primary-color;
      font-weight: 500;
    }
  }

  .welcome-decoration {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(
      45deg,
      rgba($primary-color, 0.1),
      rgba($secondary-color, 0.1)
    );
    filter: blur(40px);
    opacity: 0.5;

    &.top-right {
      top: -100px;
      right: -100px;
    }

    &.bottom-left {
      bottom: -100px;
      left: -100px;
    }
  }
}

@keyframes borderRotate {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
}
