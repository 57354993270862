$primary-color: #eb4abc;
$secondary-color: #cbf73f;
$dark-color: #1b1b1b;
$light-color: #f4f5ef;

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.admin-login {
  min-height: 100vh;
  display: grid;
  place-items: center;
  background: linear-gradient(
    135deg,
    rgba($dark-color, 0.97) 0%,
    rgba($dark-color, 0.95) 100%
  );
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    pointer-events: none;
  }

  &::before {
    background: radial-gradient(
        circle at 20% 30%,
        rgba($primary-color, 0.05) 0%,
        transparent 50%
      ),
      radial-gradient(
        circle at 80% 70%,
        rgba($secondary-color, 0.05) 0%,
        transparent 50%
      );
    animation: rotate 20s linear infinite;
  }

  &::after {
    background: linear-gradient(
        45deg,
        transparent 45%,
        rgba($secondary-color, 0.03) 50%,
        transparent 55%
      ),
      linear-gradient(
        -45deg,
        transparent 45%,
        rgba($secondary-color, 0.03) 50%,
        transparent 55%
      );
    background-size: 200% 200%;
    animation: gradientShift 8s ease-in-out infinite;
  }

  &-container {
    position: relative;
    width: 100%;
    max-width: 420px;
    padding: 2rem;
    transform: translateY(-5%);
  }

  &-box {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    padding: 3rem 2.5rem;
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(255, 255, 255, 0.1) inset;
    border: 1px solid rgba($secondary-color, 0.1);
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      background: linear-gradient(
        45deg,
        transparent,
        rgba($secondary-color, 0.1),
        transparent,
        rgba($primary-color, 0.1)
      );
      z-index: -1;
      animation: borderGlow 3s linear infinite;
      border-radius: 24px;
    }

    .admin-login-logo {
      margin-bottom: 2.5rem;
      text-align: center;
      position: relative;

      svg {
        width: 140px;
        height: auto;
        filter: brightness(0) invert(1);
        opacity: 0.9;
        transition: all 0.3s ease;
        justify-self: center;
        &:hover {
          filter: brightness(0) invert(1)
            drop-shadow(0 0 8px rgba($secondary-color, 0.5));
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 80px;
        height: 2px;
        background: linear-gradient(
          90deg,
          transparent,
          rgba($secondary-color, 0.5),
          transparent
        );
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    h1 {
      font-size: 1.75rem;
      font-weight: 700;
      margin-bottom: 2.5rem;
      color: $light-color;
      text-align: center;
      letter-spacing: -0.5px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 3px;
        background: $secondary-color;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 2px;
      }
    }

    .input-group {
      position: relative;
      margin-bottom: 1.5rem;

      input {
        width: 100%;
        padding: 1.1rem 1rem 1.1rem 3rem;
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        font-size: 1rem;
        color: $light-color;
        transition: all 0.3s ease;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &:focus {
          outline: none;
          border-color: rgba($secondary-color, 0.5);
          box-shadow: 0 0 0 4px rgba($secondary-color, 0.1);
          background: rgba(255, 255, 255, 0.05);
        }
      }

      .input-icon {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: rgba($secondary-color, 0.8);
        font-size: 1.1rem;
      }
    }

    .login-button {
      width: 100%;
      padding: 1.1rem;
      background: $primary-color;
      color: $light-color;
      border: none;
      border-radius: 12px;
      font-weight: 600;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;
      border: 1px solid rgba($secondary-color, 0.3);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          45deg,
          transparent 0%,
          rgba($secondary-color, 0.1) 50%,
          transparent 100%
        );
        transform: translateX(-100%);
        transition: transform 0.6s ease;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba($primary-color, 0.3),
          0 0 0 2px rgba($secondary-color, 0.1) inset;

        &::before {
          transform: translateX(100%);
        }
      }

      &:active {
        transform: translateY(0);
      }

      .spinner {
        animation: spin 1s linear infinite;
      }
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}

@keyframes gradientShift {
  0%,
  100% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
}

@keyframes borderGlow {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
}

// Responsive adjustments
@media (max-width: 480px) {
  .admin-login {
    &-box {
      padding: 2rem 1.5rem;
    }

    &-logo svg {
      width: 120px;
    }

    h1 {
      font-size: 1.5rem;
    }
  }
}
