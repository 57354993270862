$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$grey: #d3d3d3;
$greyer: #a2a2a2;
$white: #f4f5ef;

.action-tile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: white;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-width: 0;

  .checkmark {
    display: flex;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &-icon {
    display: block;
  }

  &-icon-small {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &-label {
    font-size: 0.7rem;
    word-break: break-word;
    hyphens: auto;
  }

  &--checked {
    background-color: $green;
  }

  &--disabled {
    background-color: $grey;
    color: $greyer;
    opacity: 0.8;
    pointer-events: none;
    cursor: none;

    svg {
      fill: $greyer;
    }
  }
}
