$breakpoints: (
  phone: 480px,
  tablet: 768px,
  tablet-landscape: 992px,
  laptop: 1200px,
  desktop: 1500px,
  desktop-large: 1920px,
  desktop-extra-large: 2560px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

@mixin grid($key) {
  display: grid;
  grid-template-columns: repeat($key, minmax(min-content, max-content));
}

@mixin grid-row($key) {
  display: grid;
  grid-template-rows: repeat($key, minmax(min-content, max-content));
}
@mixin grid-auto($type: "columns") {
  display: grid;
  grid-gap: 1rem; /* Optional, to add space between the child elements */

  @if $type == "columns" {
    grid-template-columns: repeat(auto-fill, minmax(min-content, max-content));
  } @else if $type == "rows" {
    grid-template-rows: repeat(auto-fill, minmax(min-content, max-content));
  }
}

.challenge-dashboard {
  display: grid;
  padding: 2rem;

  display: grid;
  &-no-challenge {
    text-align: center;
    margin-top: 1rem;
  }

  &-header {
    justify-self: center;

    svg {
      width: 7rem;
    }
  }

  &-challenge {
    margin-top: 1rem;
    border: 1px solid black;
    border-radius: 0.5rem;
    padding: 1rem;
    position: relative;
    max-width: 20rem;
    justify-self: center;
    cursor: pointer;
    display: grid;
    text-align: center;
    grid-gap: 0.5rem;

    &-image {
      margin-bottom: 1rem;
    }

    .challenge-over-text {
      opacity: 0.5;
      pointer-events: none; // Prevent clicking on challenges that are over

      &:before {
        content: "VORBEI";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10; // to be above other elements
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 0.5rem 1rem;
        padding-top: 5rem;
        border-radius: 0.5rem;
        font-weight: bold;
        font-size: 1.5rem;
      }
    }
  }

  &-pay-now {
    background-color: #cbf73f;
    align-items: center;
    margin-top: 0.5rem;
    font-weight: 800;
    width: 60%;
    height: 3.5rem;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    border: none;
    border-radius: 1.563rem;
    text-align: center;
    justify-self: center;
  }

  &-code {
    font-size: 0.75rem;
    font-style: italic;
  }
}
