$breakpoints: (
  phone: 480px,
  tablet: 768px,
  tablet-landscape: 992px,
  laptop: 1200px,
  desktop: 1500px,
  desktop-large: 1920px,
  desktop-extra-large: 2560px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

@mixin grid($key) {
  display: grid;
  grid-template-columns: repeat($key, minmax(min-content, max-content));
}

@mixin grid-row($key) {
  display: grid;
  grid-template-rows: repeat($key, minmax(min-content, max-content));
}
@mixin grid-auto($type: "columns") {
  display: grid;
  grid-gap: 1rem; /* Optional, to add space between the child elements */

  @if $type == "columns" {
    grid-template-columns: repeat(auto-fill, minmax(min-content, max-content));
  } @else if $type == "rows" {
    grid-template-rows: repeat(auto-fill, minmax(min-content, max-content));
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.confirmation-dialog {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); // Black background with opacity
  z-index: 99;
  @include mq(tablet) {
    width: 25rem;
    height: 20rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .confirmation-dialog-content {
    display: grid;
    background-color: #fefefe;

    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    height: 35%;

    margin-left: auto;
    margin-right: auto;
    margin-top: 0;

    @include mq(tablet) {
      height: 100%;
    }

    h2 {
      font-weight: 800;
      text-align: center;
      font-size: 1.5rem;
    }

    p {
      color: #31302e;
      text-align: center;
      font-family: Montserrat;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
      margin-top: 0.5rem;
    }

    .confirmation-dialog-actions {
      display: grid;
      grid-template-rows: repeat(2, minmax(min-content, max-content));
      grid-gap: 0.5rem;
      justify-self: center;

      button {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
        width: 18.125rem;

        flex-shrink: 0;
        border-radius: 1.5625rem;
        background: #eb4abc;
        margin: 0;

        &:nth-child(2) {
          color: #d3d3d3;
          background: none;
        }
      }
    }
  }
}
