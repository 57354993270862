$breakpoints: (
  phone: 480px,
  tablet: 768px,
  tablet-landscape: 992px,
  laptop: 1200px,
  desktop: 1500px,
  desktop-large: 1920px,
  desktop-extra-large: 2560px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

@mixin grid($key) {
  display: grid;
  grid-template-columns: repeat($key, minmax(min-content, max-content));
}

@mixin grid-row($key) {
  display: grid;
  grid-template-rows: repeat($key, minmax(min-content, max-content));
}
@mixin grid-auto($type: "columns") {
  display: grid;
  grid-gap: 1rem; /* Optional, to add space between the child elements */

  @if $type == "columns" {
    grid-template-columns: repeat(auto-fill, minmax(min-content, max-content));
  } @else if $type == "rows" {
    grid-template-rows: repeat(auto-fill, minmax(min-content, max-content));
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.analyse {
  padding-bottom: 3rem;

  &-content-habit,
  &-content-woche {
  }

  &-content-woche {
    @include mq(tablet) {
      width: 60%;
      justify-self: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;
    }
  }

  &-woche {
    &-header {
      display: grid;
      grid-template-columns: 0.05fr 0.95fr;
      width: 100%;

      justify-items: center;

      @include mq(tablet) {
        margin-bottom: 2rem;
      }

      &-tag {
        height: 2.5rem;
        text-align: center;
        padding-top: 0.75rem;
        font-weight: 800;
        font-size: 0.75rem;
        color: #1b1b1b;
      }

      &-days {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);

        height: 2.5rem;
        text-align: center;
        align-self: center;
        align-items: center;
        align-content: center;
        @include grid-row(2);

        p {
          font-size: 0.75rem;
          line-height: 0.75rem;
          font-weight: 800;
          color: #1b1b1b;
        }
      }
    }

    &-single {
      @include grid-row(2);
      grid-template-columns: 0.05fr 0.95fr;

      width: 100%;
      height: 100%;
      margin-bottom: 1rem;

      @include mq(tablet) {
        margin-bottom: 2rem;
      }
      &-number {
        grid-column: 1/2;
        grid-row: 1/3;
        background-color: #1b1b1b;
        width: 1rem;
        height: 100%;

        p {
          color: #fff;
          text-align: center;
          font-family: Montserrat;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;
        }
      }

      &-dailies {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 0.25rem;
        &-title {
          color: #1b1b1b;
          font-family: Montserrat;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;
          grid-column: 1/8;
          grid-row: 1/2;
        }

        &-box {
          display: grid;
          grid-template-rows: repeat(3, 1fr);
          width: 100%;
          height: 3.1875rem;
          background-color: $green;
          text-align: center;
          grid-row: 2/3;
          &-date {
            background-color: #fff;
            border: 2px solid $green;
            color: #31302e;
            text-align: center;
            font-family: Montserrat;
            font-size: 0.625rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.875rem;
          }

          svg {
            width: 0.6875rem;
            height: 0.38194rem;
            justify-self: center;
            align-self: end;
          }

          &-percent {
            color: #1b1b1b;
            text-align: right;
            font-family: Montserrat;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: center;
            grid-row: 3/4;
          }
        }
      }

      &-weeklies {
        position: sticky;
        &-title {
          color: #1b1b1b;
          font-family: Montserrat;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;
          margin-top: 0.2rem;
        }

        &-box {
          display: grid;

          svg {
            width: 0.6875rem;
            height: 0.38194rem;
            align-self: center;
            grid-row: 1/2;
            grid-column: 1/2;
            justify-self: start;
            align-self: center;
            z-index: 1;
            margin-left: 0.5rem;
          }

          &-progress {
            width: 100%;
            height: 20px; /* or any desired height */
            background-color: #d5d8ca; /* gray or any desired color for unfilled portion */
            overflow: hidden;
            grid-row: 1/2;
            grid-column: 1/2;

            &-fill {
              height: 100%;
              background-color: $green;
              transition: width 2s ease-out;
              display: grid;
              &-percent {
                color: #1b1b1b;
                text-align: right;
                font-family: Montserrat;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                text-align: center;
                align-self: center;
                justify-self: end;
                z-index: 1;
                padding-left: 0.5rem;
              }
            }
          }
        }
      }
    }
  }

  &-dailies,
  &-weeklies {
    overflow-x: auto;
    @include grid-row(2);
    grid-gap: 0.15rem;

    &-header {
      grid-gap: 0.15rem;

      justify-items: center;

      &-tag {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        padding-top: 0.75rem;
        font-weight: 800;
        font-size: 0.75rem;
      }

      div {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        align-self: center;
        align-items: center;
        align-content: center;
        @include grid-row(2);

        p:nth-child(1) {
          font-weight: 800;
        }

        p {
          font-size: 0.75rem;
          line-height: 0.75rem;
        }
      }
    }

    &-habits {
      grid-gap: 0.15rem;

      &-icon {
        display: grid;

        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        align-content: center;
        background-color: $dark;
        svg {
          width: 1.5rem;
          fill: #fff;
        }
      }

      span {
        width: 2.5rem;
        height: 2.5rem;
        background-color: #d5d8ca;
      }
    }
  }
}

.future-habit {
  background-color: #f9fbed !important;
  display: grid;
  svg {
    width: 0.75rem;
    justify-self: center;
    align-self: center;
    z-index: 2;
  }
}

.future-habit2 {
  background-color: #f9fbed !important;
  display: grid;

  &-date {
    border: 2px solid #f9fbed !important;
    background-color: #f9fbed !important;
  }
  svg {
    width: 0.75rem !important;
    margin-top: 0.5rem;
    height: auto !important;
    justify-self: center;
    align-self: center;
    z-index: 2;
  }
}
