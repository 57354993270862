$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;

.dailies {
  &-action {
    .countdown {
      grid-auto-columns: max-content;

      &-days-number,
      &-hours-number,
      &-minutes-number,
      &-seconds-number {
        font-size: 1rem;
      }
    }
  }

  &-timer {
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, max-content));
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    .countdown {
      display: grid;
      width: 7rem;
      span {
        min-width: 1.6rem;
      }

      span:last-child {
        text-align: left;
      }
    }
    &-title {
      font-weight: 800;
      width: 7rem;
    }

    &-text {
      font-size: 0.58rem;

      text-align: center;
      @include mq(small) {
        font-size: 1rem;
      }
    }
  }
}
