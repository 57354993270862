$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;

.weeklie {
  &-action {
    .countdown {
      display: inline-block;
      grid-auto-columns: max-content;

      &-days-number,
      &-hours-number,
      &-minutes-number,
      &-seconds-number {
        font-size: 1rem;
      }
    }
  }

  &-timer {
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, max-content));
    grid-gap: 0.5rem;
    justify-content: center;
    align-items: center;

    .countdown {
      display: grid;
      width: 7rem;
      span {
        min-width: 1.6rem;
      }

      span:last-child {
        text-align: left;
      }
    }
    &-title {
      font-weight: 800;
      justify-self: start;
      width: 7rem;
      text-align: left;
    }

    &-text {
      font-size: 0.58rem;
      @include mq(small) {
        font-size: 1rem;
      }
    }
  }
}

.modal__content {
  display: grid !important;
}

.modal__text h1 {
  font-size: 2em; /* 2 times the size of the root element */
  font-weight: bold; /* bold text */
  margin: 0.67em 0; /* top and bottom margin */
}

.modal__text h2 {
  font-size: 1.5em; /* 1.5 times the size of the root element */
  font-weight: bold; /* bold text */
  margin: 0.83em 0; /* top and bottom margin */
}

.modal__text h3 {
  font-size: 1.17em; /* 1.17 times the size of the root element */
  font-weight: bold; /* bold text */
  margin: 1em 0; /* top and bottom margin */
}

.modal__text h4 {
  font-size: 1em; /* same size as the root element */
  font-weight: bold; /* bold text */
  margin: 1.33em 0; /* top and bottom margin */
}
