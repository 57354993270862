.countdown {
  display: grid;
  grid-template-areas:
    "daysNumber hoursNumber minutesNumber secondsNumber"
    "days hours minutes seconds";

  &-days-number {
    grid-area: daysNumber;
  }

  &-hours-number {
    grid-area: hoursNumber;
  }

  &-minutes-number {
    grid-area: minutesNumber;
  }

  &-seconds-number {
    grid-area: secondsNumber;
  }

  &-days {
    grid-area: days;
  }

  &-hours {
    grid-area: hours;
  }

  &-minutes {
    grid-area: minutes;
  }

  &-seconds {
    grid-area: seconds;
  }
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

#file-input {
  display: none;
}

input::-webkit-datetime-edit {
  padding: 0;
}
input::-webkit-datetime-edit-fields-wrapper {
  background: none;
  display: flex;
}
input::-webkit-datetime-edit-day-field,
input::-webkit-datetime-edit-month-field,
input::-webkit-datetime-edit-year-field {
  border: 0px solid #d3d3d3;
  background-color: white;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: uppercase;
}
input[type="date" i]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: calc(100% - 2rem);
  background-position: right;
}
