//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.registered {
  width: 100vw;
  height: 100vh;
  background-color: $white;
  display: grid;
  grid-template-rows: repeat(3, minmax(min-content, max-content));

  &-title {
    font-weight: 800;
    text-align: center;
    margin-top: 5rem;
    font-size: 1.8rem;
    padding-bottom: 1rem;
  }

  &-sub {
    text-align: center;
    font-size: 1rem;
    font-weight: 800;
    padding-bottom: 2rem;
  }

  &-text {
    justify-self: center;
    width: 18rem;

    font-size: 0.75rem;
    font-weight: 400;
  }
}
