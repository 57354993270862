$primary-color: #eb4abc; // Pink
$secondary-color: #cbf73f; // Green
$dark-color: #1b1b1b;
$light-color: #f4f5ef;

.compact-color-picker {
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;
  z-index: 1;

  &:focus-within,
  &:hover {
    z-index: 100;
  }

  .color-display {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    border: 1px solid rgba($primary-color, 0.2);
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.03);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      border-color: rgba($primary-color, 0.5);
      box-shadow: 0 2px 8px rgba($primary-color, 0.15);
      background: rgba(255, 255, 255, 0.05);
    }

    .color-preview {
      width: 32px;
      height: 32px;
      border-radius: 8px;
      border: 2px solid rgba($primary-color, 0.2);
      transition: all 0.2s ease;

      &:hover {
        border-color: rgba($primary-color, 0.5);
        transform: scale(1.05);
      }
    }

    input {
      flex: 1;
      border: none;
      font-size: 0.95rem;
      color: $light-color;
      background: transparent;
      padding: 0.25rem;
      cursor: pointer;
      font-family: monospace;
      letter-spacing: 0.5px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: rgba($light-color, 0.5);
      }
    }
  }

  .color-picker-popover {
    position: fixed;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    isolation: isolate;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

    .color-picker-cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(3px);
      z-index: -1;
    }

    .picker-controls {
      position: absolute;
      top: -12px;
      right: -12px;
      display: flex;
      gap: 8px;
      z-index: 1;

      button {
        background: $primary-color;
        border: 2px solid rgba($light-color, 0.2);
        border-radius: 50%;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 2px 8px rgba($primary-color, 0.3);
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.1);
          background: lighten($primary-color, 5%);
        }

        &.save-picker:hover {
          transform: scale(1.1);
        }

        &.close-picker:hover {
          transform: scale(1.1) rotate(90deg);
        }

        svg {
          color: $light-color;
          font-size: 0.9rem;
        }
      }
    }

    .chrome-picker {
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2),
        0 0 0 1px rgba($primary-color, 0.1) !important;

      &::after {
        font-family: "Montserrat", sans-serif;
        content: "";
        position: absolute;
        inset: -1px;
        border-radius: 8px;
        background: linear-gradient(
          45deg,
          rgba($primary-color, 0.3),
          rgba($secondary-color, 0.3)
        );
        z-index: -1;
        animation: borderGlow 2s ease-in-out infinite;
      }

      touch-action: none; // Prevents scrolling while dragging on mobile

      // Additional fix for Safari
      * {
        touch-action: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
      }
    }
  }
}

@keyframes borderGlow {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
}
