@import "../../styles/variables";

.challenge-global-settings {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  overflow: hidden;
  margin-top: 1.5rem;

  .settings-section {
    .section-header {
      padding: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s ease;
      background: rgba(255, 255, 255, 0.02);
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);

      &:hover {
        background: rgba(255, 255, 255, 0.04);
      }

      .section-title {
        display: flex;
        align-items: center;
        gap: 1rem;

        svg {
          color: $secondary-color;
          font-size: 1.2rem;
        }

        h3 {
          margin: 0;
          font-size: 1.2rem;
          color: $light-color;
        }
      }

      .section-toggle {
        color: rgba($light-color, 0.6);
        transition: transform 0.3s ease;

        &.active {
          transform: rotate(180deg);
          color: $primary-color;
        }
      }
    }

    &.active {
      .section-content {
        animation: slideDown 0.3s ease forwards;
      }
    }

    .section-content {
      padding: 1.5rem;
      display: grid;
      gap: 1.5rem;

      .form-group {
        display: grid;
        gap: 0.5rem;

        label {
          color: rgba($light-color, 0.8);
          font-size: 0.9rem;
          font-weight: 500;
        }

        input {
          width: 100%;
          padding: 0.9rem 1rem;
          background: rgba(255, 255, 255, 0.03);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          color: $light-color;
          font-size: 0.95rem;
          transition: all 0.3s ease;

          &:focus {
            outline: none;
            border-color: rgba($primary-color, 0.5);
            box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
            background: rgba(255, 255, 255, 0.05);
          }
        }

        // Rich Text Editor Styles
        .rich-text-editor {
          .ql-toolbar {
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .ql-stroke {
              stroke: $light-color;
            }

            .ql-fill {
              fill: $light-color;
            }

            .ql-picker {
              color: $light-color;

              &-label {
                color: $light-color;
              }

              &-options {
                background: $dark-color;
                border: 1px solid rgba(255, 255, 255, 0.1);
              }
            }

            button:hover {
              .ql-stroke {
                stroke: $primary-color;
              }
              .ql-fill {
                fill: $primary-color;
              }
            }
          }

          .ql-container {
            height: 200px;
            background: rgba(255, 255, 255, 0.03);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            font-family: inherit;
            color: $light-color;

            .ql-editor {
              &.ql-blank::before {
                color: rgba($light-color, 0.4);
              }

              p {
                color: $light-color;
              }

              a {
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden-users-list {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .hidden-user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .user-info {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .user-avatar,
      .user-avatar-placeholder {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
      }

      .user-avatar-placeholder {
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-color;
      }
    }

    .unhide-button {
      background: none;
      border: none;
      color: $light-color;
      opacity: 0.6;
      cursor: pointer;
      padding: 0.5rem;
      transition: all 0.2s ease;

      &:hover {
        opacity: 1;
        color: $primary-color;
      }
    }
  }
}
