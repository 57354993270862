//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.knowledge {
  svg {
    cursor: pointer;
  }
  &-header {
    display: grid;
    height: 8rem;
    background-color: $dark;
    color: white;
    text-align: center;
    font-weight: 800;
    justify-content: center;
    justify-items: center;

    svg {
      fill: #fff;
      width: 5rem;
    }
  }

  &-group {
    padding: 1rem;
    &-title {
      text-align: center;
      font-weight: 800;
      font-size: 1.25rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &-group:last-of-type {
    padding-bottom: 5rem;
  }
}
