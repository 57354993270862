// //

// $breakpoints: (
//   small: 480px,
//   bloody: 650px,
//   midsmall: 800px,
//   welldone: 1000px,
//   medium: 1200px,
//   large: 1800px,
// );

// @mixin mq($key) {
//   $size: map-get($breakpoints, $key);
//   @media (min-width: $size) {
//     @content;
//   }
// }

// $green: #cbf73f;
// $pink: #eb4abc;
// $dark: #1b1b1b;

// .lifestyle {
//   display: grid;
//   justify-content: center;
//   justify-items: center;

//   &-title {
//     font-size: 1.3rem;
//     margin-bottom: 0.7rem;
//     font-weight: 800;
//   }

//   &-tracking {
//     display: grid;
//     grid-template-columns: repeat(3, minmax(min-content, max-content));
//     grid-gap: 0.5rem;

//     &-time {
//       font-weight: 800;
//     }

//     &-title {
//       font-weight: 800;
//     }
//   }

//   &-app {
//     display: grid;

//     grid-template-rows: repeat(2, minmax(min-content, max-content));
//     grid-template-columns: repeat(3, minmax(min-content, max-content));
//     grid-gap: 1rem;
//     margin-top: 1rem;

//     @include mq(welldone) {
//       grid-template-columns: repeat(6, minmax(min-content, max-content));
//       grid-template-rows: 1fr;
//     }

//     &s {
//       display: grid;
//       position: relative;
//       align-items: center;
//       justify-items: center;
//       width: 5.625rem;
//       height: 3.75rem;
//       border-radius: 0.5rem;
//       background-color: white;
//     }

//     &-check {
//       display: none;
//       position: absolute;
//       right: 0.2rem;
//       top: 0.3rem;
//     }
//   }
// }

// .app-is-check {
//   background-color: $green;
// }

// .check-is-check {
//   display: block;
// }

// .reset-image {
//   margin-bottom: 1rem;

//   @include mq(welldone) {
//     margin-bottom: 1rem !important;
//   }
// }

.lifestyle-setup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1.5rem;
}