$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$grey: #d3d3d3;
$white: #f4f5ef;

.page-container {
  min-height: calc(100vh - 120px); // Account for header and footer
  position: relative;
  overflow-y: auto;
}

.chat {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 1rem;
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 1rem;
  height: 100%;
  max-height: calc(100vh - 120px); // Prevent overflow

  &-title {
    font-weight: 800;
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  &-text {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 1rem;

    br {
      display: block;
      margin: 0.5rem 0;
    }

    a {
      display: inline-block;
      margin-top: 1rem;
      word-break: break-word; // Handle long URLs better
    }
  }

  &-link {
    text-decoration: underline;
    font-weight: 800;
  }
}

// Add media query for smaller screens
@media (max-width: 480px) {
  .chat {
    padding: 0.5rem;

    &-title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    &-text {
      padding: 0 0.5rem;
    }
  }
}
