.search-user {
  $self: &;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  &__hidden-list {
    margin-bottom: 2rem;
    background: #2a2a2a;
    border-radius: 0.75rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(#eb4abc, 0.2);

    h3 {
      color: #eb4abc;
      font-size: 1rem;
      margin-bottom: 1.25rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &::before {
        content: "🚫";
        font-size: 1.1rem;
      }
    }
  }

  &__hidden-items {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .search-user__item {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 0.5rem;
      border: 1px solid rgba(255, 255, 255, 0.1);
      padding: 1rem;

      &:hover {
        background: rgba(255, 255, 255, 0.08);
        border-color: rgba(#eb4abc, 0.3);
      }

      .search-user__name {
        color: white;
      }

      .search-user__email {
        color: rgba(255, 255, 255, 0.6);
      }

      .search-user__action {
        color: #eb4abc;
        opacity: 0.8;

        &:hover {
          opacity: 1;
          background: rgba(#eb4abc, 0.2);
        }
      }
    }
  }

  &__input-wrapper {
    position: relative;
    margin-bottom: 1rem;
  }

  &__icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #eb4abc;
    z-index: 1;
  }

  &__input {
    width: 100%;
    padding: 0.75rem 3rem;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 0.5rem;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    background: white;
    color: #333;
    padding-left: 3rem !important;
    &::placeholder {
      color: rgba($color: #ffffff, $alpha: 0.4);
    }

    &:focus {
      outline: none;
      border-color: #eb4abc;
      box-shadow: 0 0 0 2px rgba(#eb4abc, 0.1);
    }
  }

  &__results {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 0.5rem;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    transition: all 0.2s ease;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: rgba(#eb4abc, 0.03);
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: #999;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-weight: 500;
    color: #333;
  }

  &__email {
    font-size: 0.8rem;
    color: rgba($color: #000000, $alpha: 0.5);
  }

  &__action {
    padding: 0.5rem;
    border: none;
    background: none;
    cursor: pointer;
    color: #eb4abc;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;

    &:hover {
      background: rgba(#eb4abc, 0.1);
      transform: scale(1.1);
    }
  }

  &__loading {
    padding: 1rem;
    text-align: center;
    color: rgba($color: #000000, $alpha: 0.5);
  }
}
