//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}


.law {
    padding: 2rem;

    &-title {
        text-align: center;
        font-weight: 800;
        font-size: 1.5rem;
        padding-bottom: 2rem;
    }
}