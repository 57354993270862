@import "../../styles/variables";

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($dark-color, 0.8);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  animation: fadeIn 0.2s ease;
}

.dialog-content {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 16px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  position: relative;
  color: $light-color;
  animation: slideUp 0.3s ease;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1px;
    background: linear-gradient(
      45deg,
      transparent,
      rgba($primary-color, 0.3),
      rgba($secondary-color, 0.3),
      transparent
    );
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
    pointer-events: none;
  }
}

.dialog-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: rgba($light-color, 0.5);
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $light-color;
    transform: scale(1.1);
  }
}

.dialog-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    background: linear-gradient(45deg, $light-color, rgba($primary-color, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .dialog-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;

    &.warning {
      background: rgba(#f1c40f, 0.1);
      color: #f1c40f;
    }

    &.danger {
      background: rgba(#ff4545, 0.1);
      color: #ff4545;
    }

    &.info {
      background: rgba(#3498db, 0.1);
      color: #3498db;
    }
  }
}

.dialog-body {
  margin-bottom: 2rem;

  p {
    margin: 0;
    color: rgba($light-color, 0.8);
    line-height: 1.6;
  }
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  .dialog-button {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;

    &.cancel {
      background: rgba(255, 255, 255, 0.05);
      color: $light-color;
      border: 1px solid rgba(255, 255, 255, 0.1);

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    &.confirm {
      &.warning {
        background: #f1c40f;
        color: $dark-color;
        &:hover {
          background: darken(#f1c40f, 10%);
          transform: translateY(-2px);
        }
      }

      &.danger {
        background: #ff4545;
        color: $light-color;
        &:hover {
          background: darken(#ff4545, 10%);
          transform: translateY(-2px);
        }
      }

      &.info {
        background: #3498db;
        color: $light-color;
        &:hover {
          background: darken(#3498db, 10%);
          transform: translateY(-2px);
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
