$breakpoints: (
  phone: 480px,
  tablet: 768px,
  tablet-landscape: 992px,
  laptop: 1200px,
  desktop: 1500px,
  desktop-large: 1920px,
  desktop-extra-large: 2560px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

@mixin grid($key) {
  display: grid;
  grid-template-columns: repeat($key, minmax(min-content, max-content));
}

@mixin grid-row($key) {
  display: grid;
  grid-template-rows: repeat($key, minmax(min-content, max-content));
}
@mixin grid-auto($type: "columns") {
  display: grid;
  grid-gap: 1rem; /* Optional, to add space between the child elements */

  @if $type == "columns" {
    grid-template-columns: repeat(auto-fill, minmax(min-content, max-content));
  } @else if $type == "rows" {
    grid-template-rows: repeat(auto-fill, minmax(min-content, max-content));
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.teamDashboard {
  display: grid;

  &-header {
    background: linear-gradient(180deg, #f8faec 50%, #abad9f 79.92%);
    display: grid;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-top: 3rem;

    @include mq(tablet) {
      padding-top: 8rem;
    }
    &-avatar {
      // width: 5.5rem;
      // height: 5.5rem;
      position: fixed;
      top: 3.5rem; /* This will position the top of the element at 3.5rem from the top */
      left: 50%; /* This will position the left edge of the element at the center of the screen */
      transform: translateX(
        -50%
      ); /* This will shift the element to the left by half its width, effectively centering it */
      z-index: 99;
      margin-bottom: 1rem;
      display: grid; /* Assuming you need 'display: grid' for content inside the element */

      @include mq(tablet) {
        width: 8rem;
        height: 8rem;
        margin-bottom: 3rem;
      }

      h2 {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }
      img {
        width: 5.5rem;
        height: 5.5rem;
        border-radius: 50%;
        border: 1px solid black;
        justify-self: center;
      }

      div {
        justify-self: center;
        margin-top: -0.5rem;
      }
    }

    .profileApp {
      justify-self: center;
      svg {
        width: 6rem;
        height: 6rem;
      }
    }

    &-scores {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @include mq(tablet) {
        justify-self: center;
        @include grid(3);
        grid-gap: 3rem;
      }

      .score {
        width: 8rem;
        justify-self: center;

        @include mq(tablet) {
          width: 12rem !important;
        }
      }

      .score:nth-child(3) {
        width: 12rem;
        grid-column: 1/3;
        grid-row: 2/3;

        @include mq(tablet) {
          grid-column: 3/4;
          grid-row: 1/2;
        }
      }

      h3 {
        font-size: 1rem;
      }
    }
  }

  &-members {
    @include grid-row(4);

    @include mq(tablet) {
      width: 60%;
      justify-self: center;
    }
  }

  &-member {
    display: grid;
    grid-template-columns: 0.2fr 0.5fr 0.2fr 0.1fr;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #d3d3d3;
    padding: 0.5rem;
    position: relative;

    &-name {
      p:nth-child(1) {
        font-weight: 800;

        @include mq(tablet) {
          font-size: 1.15rem;
        }
      }

      p:nth-child(2) {
        color: #31302e;

        font-family: Montserrat;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.875rem;
      }
    }

    &-avatar {
      width: 2.5rem;
      border-radius: 50%;

      @include mq(tablet) {
        width: 3.5rem;
      }
    }

    &-percent {
      @include grid-row(2);
      justify-self: end;
      p:nth-child(1) {
        font-weight: 800;
        @include mq(tablet) {
          font-size: 1.15rem;
        }
      }

      p:nth-child(2) {
        color: #31302e;

        font-family: Montserrat;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.875rem;
      }

      .daily-score {
        font-size: 0.8em;
        color: #666;
        margin-top: 0.2rem;
      }
    }

    &-admin {
      justify-self: end;
      margin-right: 0.5rem;
    }

    &-admin-settings {
      display: grid;
      grid-template-columns: 0.45fr 0.45fr 0.1fr;
      position: absolute;
      background-color: black;
      width: 100%;
      height: 100%;
      opacity: 0.9;
      align-items: center;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      button {
        color: #fff;
        font-size: 0.75rem;
      }

      svg {
        justify-self: end;
      }
    }
  }

  &-invite {
    @include grid-row(4);
    grid-gap: 1rem;
    justify-self: center;
    margin-top: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #d3d3d3;
    background: #f4f5ef;
    padding: 1rem;
    width: 90%;
    max-width: 500px;

    @include mq(tablet) {
      width: 60%;
      justify-self: center;
    }

    &-title {
      justify-self: center;
      font-weight: 800;
    }

    &-text {
      color: #31302e;
      text-align: center;
      font-family: Montserrat;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
      text-align: center;
      padding: 0 0.5rem;
    }

    &-link {
      color: #1b1b1b;
      justify-self: center;
      font-family: Montserrat;
      font-size: 0.7em;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-align: center;
      width: 100%;
      word-break: break-all;
      padding: 0 0.5rem;
      overflow-wrap: break-word;
    }

    &-button {
      color: #eb4abc;
      justify-self: center;
      font-family: Montserrat;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  &-delete {
    color: #d3d3d3;
    text-align: center;
    font-family: Montserrat;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 3rem;
  }
}
