//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.verified {
  width: 100vw;
  height: 100vh;
  background-color: $white;
  display: grid;
  grid-template-rows: repeat(4, minmax(min-content, max-content));

  &-title {
    font-weight: 800;
    text-align: center;
    margin-top: 5rem;
    font-size: 1.8rem;
    padding-bottom: 1rem;
  }

  &-sub {
    text-align: center;
    font-size: 1rem;
    font-weight: 800;
    padding-bottom: 2rem;
  }

  &-text {
    justify-self: center;
    width: 18rem;
    text-align: center;
    font-size: 0.75rem;

    padding-bottom: 0.5rem;
  }

  &-button {
    margin-top: 1rem;
    width: 18.125rem;
    height: 3.125rem;
    border: none;
    background-color: #eb4abc;
    color: white;
    border-radius: 1.563rem;
    font-weight: 800;
    font-size: 0.813rem;
    align-self: start;
    justify-self: center;

    @include mq(welldone) {
      margin-top: 1rem;
      width: 15.125rem;
      height: 2.525rem;
      font-size: 0.7rem;
    }
  }
}
