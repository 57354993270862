//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.paywall {
  display: grid;
  grid-template-rows: repeat(4, minmax(min-content, max-content));
  background-color: $white;
  justify-content: center;
  justify-items: center;
  grid-gap: 1rem;
  width: 100vw;
  height: 100vh;

  padding-top: 8rem;
  color: $dark;
  &-logo {
    width: 6rem;
  }
}
