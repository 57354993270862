.blog-settings-form {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  padding: 2rem;
  margin-bottom: 2rem;

  .header-image-section {
    margin-bottom: 2rem;
    background: rgba(255, 255, 255, 0.02);
    border: 2px dashed rgba(#cbf73f, 0.2);
    border-radius: 12px;
    padding: 2rem;

    .image-upload-container {
      text-align: center;
      margin-bottom: 1rem;

      button {
        background: rgba(#eb4abc, 0.1);
        border: 1px solid rgba(#eb4abc, 0.3);
        color: #f4f5ef;
        padding: 1rem 2rem;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        width: auto;
        min-width: 200px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-weight: 500;

        &:hover {
          background: rgba(#eb4abc, 0.2);
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(#eb4abc, 0.2);
        }
      }
    }

    .preview-container {
      max-height: 300px;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.2);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 300px;
      }
    }

    &:hover {
      border-color: rgba(#cbf73f, 0.3);
      background: rgba(255, 255, 255, 0.03);
    }
  }

  &-wrapper {
    margin: 2rem 0;
  }

  .form-section {
    margin-bottom: 1.5rem;
    position: relative;
  }

  .form-divider {
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(#cbf73f, 0.2),
      transparent
    );
    margin-top: 1rem;
  }

  &-input {
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 0.9rem 1rem;
    color: #f4f5ef;
    font-size: 0.95rem;

    &:focus {
      outline: none;
      border-color: rgba(#eb4abc, 0.5);
      background: rgba(255, 255, 255, 0.05);
    }
  }

  .category-container {
    .blog-settings-form-categories {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-top: 1rem;

      button {
        padding: 0.5rem 1rem;
        background: rgba(#cbf73f, 0.1);
        border: 1px solid rgba(#cbf73f, 0.3);
        border-radius: 20px;
        color: #cbf73f;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background: rgba(#cbf73f, 0.2);
          transform: translateY(-2px);
        }
      }
    }
  }

  .date-section {
    .label {
      color: rgba(#f4f5ef, 0.7);
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
    }

    .blog-settings-form-checkbox {
      margin-top: 1rem;
      padding: 0.75rem;
      background: rgba(255, 255, 255, 0.03);
      border: 1px solid rgba(255, 255, 255, 0.07);
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 0.75rem;

      input[type="checkbox"] {
        width: 18px;
        height: 18px;
        cursor: pointer;
        accent-color: #cbf73f;
      }

      span {
        color: rgba(#f4f5ef, 0.8);
        font-size: 0.9rem;
      }
    }
  }

  .quill {
    background: rgba(27, 27, 27, 0.95);
    border-radius: 8px;
    overflow: hidden;
    margin-top: 2rem;

    .ql-toolbar {
      background: rgba(255, 255, 255, 0.05);
      border-color: rgba(255, 255, 255, 0.1);

      .ql-stroke {
        stroke: rgba(#f4f5ef, 0.7);
      }

      .ql-fill {
        fill: rgba(#f4f5ef, 0.7);
      }

      .ql-picker {
        color: rgba(#f4f5ef, 0.7);
      }
    }

    .ql-container {
      border-color: rgba(255, 255, 255, 0.1);
      background: rgba(27, 27, 27, 0.95);
      color: #f4f5ef !important;
    }

    .ql-editor {
      min-height: 300px;
      color: #f4f5ef !important;

      &.ql-blank::before {
        color: rgba(#f4f5ef, 0.4);
      }

      p,
      span,
      strong,
      em,
      u,
      s,
      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      ol,
      ul,
      li {
        color: #f4f5ef !important;
      }

      * {
        color: #f4f5ef !important;
      }
    }
  }

  &-submit {
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;
    background: #eb4abc;
    color: #f4f5ef;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(#eb4abc, 0.3);
    }
  }
}
