$primary-color: #eb4abc;
$secondary-color: #cbf73f;
$dark-color: #1b1b1b;
$light-color: #f4f5ef;
$gray-color: #d3d3d3;

$sidebar-width: 280px;
$sidebar-collapsed-width: 80px;
$header-height: 80px;

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);
