//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$grey: #d3d3d3;
$white: #f4f5ef;

.clock-holder {
  width: 100%;
  position: relative;
}

.stopwatch {
  text-align: center;
  background-color: none;
}
.stopwatch span {
  background: $pink;
  color: #fff;
  display: inline-block;
  font-family: monospace;
  font-size: 22px;
  padding: 8px;
  border-radius: 5px;
}

.stopwatch .stopwatch-btn {
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  width: 150px;
  transition: background 1s;
  cursor: pointer;
}
.stopwatch .stopwatch-btn-red {
  border: 2px solid #c85454;
  color: #c85454;
}
.stopwatch .stopwatch-btn-yel {
  border: 2px solid #ffc900;
  color: #ffc900;
}

.stopwatch .stopwatch-btn-gre {
  border: 2px solid #00aba9;
  color: #00aba9;
}
.stopwatch .stopwatch-btn-red:hover {
  background: #c85454;
  border: 2px solid #c85454;
  color: #fff;
}
.stopwatch .stopwatch-btn-yel:hover {
  background: #ffc900;
  border: 2px solid #ffc900;
  color: #fff;
}
.stopwatch .stopwatch-btn-gre:hover {
  background: #00aba9;
  border: 2px solid #00aba9;
  color: #fff;
}
