//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;

.homeview {
  padding: 5rem 1rem 1.85rem 1rem;
  background: linear-gradient(180deg, #f8faec 46.46%, #abad9f 73.99%);

  .crown-icon {
  }
}
