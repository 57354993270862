//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.blog {
  display: grid;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  aspect-ratio: 1 1;
  border-radius: 0.5rem;
  border: 1px solid #d3d3d3;
  height: 17.5rem;
  width: 15rem;

  &-image {
    overflow: hidden;
  }

  &-content {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-rows: repeat(3, minmax(min-content, max-content));
    text-align: center;
    justify-items: center;
    align-content: center;

    &-title {
      font-weight: 800;
      font-size: 1.25rem;
    }

    &-text {
      font-size: 0.75rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    &-link {
      font-size: 0.75rem;
      font-weight: 800;
      color: #eb4abc;
    }
  }
}
