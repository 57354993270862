//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  normal: 1024px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.after-challenge {
  &-title {
    font-weight: 800;
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  &-scores {
    margin-top: 50px;
  }

  &-text {
    text-align: center;
    margin-bottom: 2rem;
  }

  &-youdidit {
    font-weight: 800;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  &-lifestyle-sub {
    text-align: center;
    grid-column: 1/3;

    @include mq(normal) {
      display: none;
    }

    h2 {
      font-weight: 800;
      font-size: 1.25rem;
    }

    p {
      color: #fff;
      font-weight: 600;
    }
  }

  &-lifestyle-sub-desktop {
    display: none;

    @include mq(normal) {
      display: block;
    }

    h2 {
      font-weight: 800;
      text-align: center;
      font-size: 1.25rem;
    }

    .sub-desktop-text {
      color: #fff;
      text-align: center;
      font-weight: 600;
    }
  }

  &-lifestyle-container {
    display: none;

    @include mq(normal) {
      display: block;
    }
  }
}

.after-challenge-lifestyle {
  display: grid;
  justify-items: center;
}

.footer-after-navigation {
  margin-left: -1rem;
}

.mobile-score {
  @include mq(normal) {
    display: none;
  }
}

.desktop-score {
  display: none;

  @include mq(normal) {
    display: block;
  }
}

body {
  background-color: #111827;
}

#confetti-wrapper {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
}

.confetti {
  width: 15px;
  height: 15px;
  background-color: #f2d74e;
  position: absolute;
  left: 50%;
  animation: confetti 5s ease-in-out -2s infinite;
  transform-origin: left top;
}

.confetti:nth-child(1) {
  background-color: #fcf6b0;
  left: 10%;
  animation-delay: 0;
}

.confetti:nth-child(2) {
  background-color: #78531e;
  left: 20%;
  animation-delay: -5s;
}

.confetti:nth-child(3) {
  background-color: #e3c47a;
  left: 30%;
  animation-delay: -3s;
}

.confetti:nth-child(4) {
  background-color: #896c41;
  left: 40%;
  animation-delay: -2.5s;
}

.confetti:nth-child(5) {
  background-color: #9a5f22;

  left: 50%;
  animation-delay: -4s;
}

.confetti:nth-child(6) {
  background-color: #ddc571;
  left: 60%;
  animation-delay: -6s;
}

.confetti:nth-child(7) {
  background-color: #8a5e25;
  left: 70%;
  animation-delay: -1.5s;
}

.confetti:nth-child(8) {
  background-color: #fcdf73;
  left: 80%;
  animation-delay: -2s;
}

.confetti:nth-child(9) {
  background-color: #4f3a20;
  left: 90%;
  animation-delay: -3.5s;
}

.confetti:nth-child(10) {
  background-color: #f0e9a0;
  left: 100%;
  animation-delay: -2.5s;
}

.confetti:nth-child(11) {
  background-color: #f0e9a3;
  left: 72%;
  animation-delay: -5.5s;
}

.confetti:nth-child(12) {
  background-color: #f0e4a0;
  left: 34%;
  animation-delay: -2.8s;
}

.confetti:nth-child(13) {
  background-color: #f0c9a0;
  left: 100%;
  animation-delay: -7.5s;
}

.confetti:nth-child(14) {
  background-color: #f0b9a0;
  left: 16%;
  animation-delay: -8.5s;
}

@keyframes confetti {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }
  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }
  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
  }
  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
  }
  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
  }
}

.text-7xl {
  font-size: 2.5rem !important;
}

.page-container {
  min-height: calc(100vh - var(--header-height));
  padding-bottom: var(--footer-height);
  position: relative;
}
