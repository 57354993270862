//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$grey: #d3d3d3;
$white: #f4f5ef;

.totalrank {
  width: 100%;
  flex-grow: 1;

  &-single {
    display: grid;
    grid-template-columns: 0.2fr 0.4fr 1fr 0.5fr;
    border-bottom: 1px solid $grey;
    padding: 0.5rem;

    &-profile {
      width: 2.5rem;
      height: 2.5rem;
      background-color: $grey;
      border-radius: 50%;
    }

    svg {
      display: block;
      width: 2.5rem !important;
    }
  }

  &-self {
    display: grid;
    width: 100%;
    z-index: 2;
    grid-template-columns: 0.2fr 0.4fr 1fr 0.5fr;
    padding: 0.5rem;
    background-color: $green;
  }

  &-rank {
    font-weight: 800;
  }

  &-name {
    font-size: 0.9rem;
  }

  &-avatar {
    border-radius: 50%;
    width: 2.5rem;
  }

  &-score {
    display: grid;
    justify-content: end;
    text-align: right;

    &-points {
      font-size: 0.6rem;
    }
  }
}
