@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");

:root {
  --swiper-theme-color: #eb4abc;
  --swiper-navigation-size: 30px;
}

.swiper {
  padding: 2rem;
}

.swiper-button-prev {
  left: 18rem;
}

.swiper-button-next {
  right: 18rem;
}

@media screen and (max-width: 1000px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.how-it-works,
.our-community,
.our-experts,
.our-press {
  .swiper-slide {
    width: 260px;
  }
}

.menshealth {
  svg {
    width: 5rem;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #fff; // or any other background color you want

  .admin-logo {
    animation: heartbeat 1.5s infinite;
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}

.home {
  &-logo-container {
    display: grid;
  }
  &-logo {
    min-width: 8rem;
  }
}

.countdown {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .countdown__units {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding: 0;
    width: 100%;
    max-width: 320px;
    justify-content: center;
  }

  .countdown__unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .countdown__number {
    font-size: 1.75rem;
    font-weight: 700;
    color: #000;
  }

  .countdown__label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #666;
    text-transform: uppercase;
  }
}

// Responsive styles for larger screens
@media (min-width: 1024px) {
  .countdown {
    max-width: 480px;

    .countdown__units {
      max-width: 480px;
    }

    .countdown__number {
      font-size: 2.5rem;
    }

    .countdown__label {
      font-size: 1rem;
    }
  }
}
.home-hero-title {
  position: relative;
  z-index: 10;
  text-align: center;
  font-size: 1.5rem;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;

  @media (min-width: 400px) {
    font-size: 2rem;
  }

  @media (min-width: 1024px) {
    font-size: 4.5rem;
    margin-bottom: 2.5rem;
  }
}
