$primary-color: #eb4abc; // Pink
$secondary-color: #cbf73f; // Green
$dark-color: #1b1b1b;
$light-color: #f4f5ef;

.homepage-settings {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  color: $light-color;

  .settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      inset: -1px;
      background: linear-gradient(
        45deg,
        transparent,
        rgba($primary-color, 0.2),
        rgba($secondary-color, 0.2),
        transparent
      );
      z-index: 0;
      animation: borderGlow 3s linear infinite;
    }

    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0;
      position: relative;
      z-index: 1;
      background: linear-gradient(
        45deg,
        $light-color,
        rgba($primary-color, 0.8)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .save-button {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.9rem 1.8rem;
      background: $primary-color;
      border: 1px solid rgba($secondary-color, 0.3);
      border-radius: 12px;
      color: $light-color;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      z-index: 1;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba($primary-color, 0.3),
          0 0 0 2px rgba($secondary-color, 0.1) inset;
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }

  .settings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1.5rem;
    align-items: start;

    .settings-section {
      &:nth-child(1) {
        z-index: 6;
      }
      &:nth-child(2) {
        z-index: 5;
      }
      &:nth-child(3) {
        z-index: 4;
      }
      &:nth-child(4) {
        z-index: 3;
      }
      &:nth-child(5) {
        z-index: 2;
      }
      &:nth-child(6) {
        z-index: 1;
      }
    }
  }

  .settings-section {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 80px; // Default height when collapsed

    &.active {
      height: auto;
      min-height: 500px; // Give enough room for color picker
      cursor: default;
      background: rgba(255, 255, 255, 0.05);
      border-color: rgba($secondary-color, 0.2);
      overflow: visible; // Allow color picker to overflow
      z-index: 1; // Base z-index

      &:focus-within,
      &:hover {
        z-index: 2; // Increase when interacting
      }

      .section-toggle {
        .close-icon {
          color: $primary-color;
        }
      }

      .section-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin-bottom: 1.5rem;
      }
    }

    &:hover:not(.active) {
      transform: translateY(-2px);
      background: rgba(255, 255, 255, 0.04);
      border-color: rgba($secondary-color, 0.15);

      .section-toggle {
        .expand-icon {
          color: $secondary-color;
        }
      }
    }

    .section-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem;
      transition: all 0.3s ease;

      .section-title {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-right: auto;

        svg {
          color: $secondary-color;
          font-size: 1.4rem;
        }

        h3 {
          font-size: 1.2rem;
          font-weight: 600;
          margin: 0;
          color: $light-color;
        }
      }

      .section-toggle {
        margin-left: auto;
        background: none;
        border: none;
        color: rgba($light-color, 0.5);
        cursor: pointer;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;

        svg {
          font-size: 1.2rem;
        }

        &:hover {
          color: $secondary-color;
        }
      }
    }

    .section-content {
      padding: 0 1.5rem 1.5rem;
      animation: slideDown 0.3s ease forwards;
      position: relative;
    }
  }

  .settings-field {
    display: grid;
    gap: 0.5rem;
    position: relative;

    label {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      font-weight: 500;
      color: rgba($light-color, 0.8);
      margin: 0;

      // Add a subtle dot before each label
      &::before {
        content: "•";
        color: $secondary-color;
        margin-right: 0.5rem;
        font-size: 1.2rem;
        line-height: 0;
      }
    }

    // Group related fields
    &[class*="color"],
    &[class*="text"] {
      margin-bottom: 1rem;
    }

    // Special styling for color-related fields
    &[class*="color"] {
      .settings-input,
      .color-display {
        background: rgba(255, 255, 255, 0.05);
        border-color: rgba(255, 255, 255, 0.1);
        color: $light-color;

        input {
          color: $light-color;
          &::placeholder {
            color: rgba($light-color, 0.4);
          }
        }
      }
    }

    .settings-input {
      width: 100%;
      padding: 0.7rem 0.9rem;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      font-size: 0.9rem;
      color: $light-color;
      transition: all 0.3s ease;
      height: 38px;

      &:focus {
        outline: none;
        border-color: rgba($primary-color, 0.5);
        box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
        background: rgba(255, 255, 255, 0.08);
      }

      &::placeholder {
        color: rgba($light-color, 0.4);
      }
    }

    // Specific styling for button-related fields
    &[class*="button"] {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
          90deg,
          transparent,
          rgba($secondary-color, 0.2),
          transparent
        );
      }
    }

    // Group styling
    & + & {
      margin-top: 1rem;
    }

    // Color picker specific adjustments
    .compact-color-picker {
      .color-display {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 0.75rem;
        border-radius: 12px;

        input {
          color: $light-color;
          background: transparent;
          font-family: monospace;
          letter-spacing: 0.5px;
        }

        .color-preview {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          border: 2px solid rgba(255, 255, 255, 0.1);
        }
      }
    }

    // Add specific styling for image upload fields
    .logo-upload {
      width: 100%;
      text-align: center;
      margin: 0.5rem 0 1.5rem;

      button {
        width: 100%;
        padding: 1rem;
        background: rgba($primary-color, 0.1);
        border: 2px dashed rgba($secondary-color, 0.3);
        border-radius: 12px;
        color: $light-color;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        &:hover {
          background: rgba($primary-color, 0.15);
          border-color: rgba($secondary-color, 0.5);
          transform: translateY(-2px);
        }

        svg {
          font-size: 1.2rem;
          color: $secondary-color;
        }
      }

      img {
        max-width: 100%;
        border-radius: 12px;
        margin: 1rem auto;
        border: 1px solid rgba($secondary-color, 0.2);
        background: rgba(255, 255, 255, 0.03);
      }
    }

    // Fix spacing between fields
    & + & {
      margin-top: 1.5rem;
    }

    // Special spacing for text inputs
    input[type="text"] {
      margin-bottom: 1rem;
    }

    // Consistent spacing for all field types
    .color-display,
    .settings-input,
    .logo-upload {
      margin-top: 0.5rem;
    }
  }

  // Add consistent section spacing
  .section-content {
    .settings-field:last-child {
      margin-bottom: 0;
    }
  }

  @keyframes borderGlow {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
    }
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    padding: 1rem;

    .settings-header {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
      padding: 1.2rem;

      h2 {
        font-size: 1.5rem;
      }
    }

    .settings-grid {
      grid-template-columns: 1fr;
    }

    .settings-section {
      width: 100%;
      padding: 1rem;

      .section-header {
        padding: 1rem;
      }

      .section-content {
        padding: 0 1rem 1rem;
      }
    }

    .settings-field {
      input,
      .color-display {
        padding: 0.75rem;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 0.5rem;

    .settings-grid {
      gap: 1rem;
    }

    .settings-section {
      padding: 0.75rem;

      .section-header {
        padding: 0.75rem;
      }

      .section-content {
        padding: 0 0.75rem 0.75rem;
      }
    }
  }
}
