//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

$primary-color: #eb4abc;
$secondary-color: #cbf73f;
$dark-color: #1b1b1b;
$light-color: #f4f5ef;
$gray-color: #d3d3d3;

$sidebar-width: 280px;
$sidebar-collapsed-width: 80px;
$header-height: 80px;

@mixin glass-effect {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@mixin hover-effect {
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

.admin-dashboard {
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 100vh;
  background: linear-gradient(
    135deg,
    rgba($dark-color, 0.97) 0%,
    rgba($dark-color, 0.95) 100%
  );
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    background: radial-gradient(
        circle at 0% 0%,
        rgba($primary-color, 0.03) 0%,
        transparent 50%
      ),
      radial-gradient(
        circle at 100% 100%,
        rgba($secondary-color, 0.03) 0%,
        transparent 50%
      );
    animation: rotate 20s linear infinite;
  }
}

.admin-sidebar {
  width: 280px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  color: $light-color;
  transition: all 0.3s ease;
  position: fixed;
  height: 100vh;
  z-index: 1000;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba($secondary-color, 0.3),
      transparent
    );
  }

  &.collapsed {
    width: 80px;

    .admin-logo {
      width: 40px;
      margin: 0 auto;
    }

    .admin-navigation-item {
      padding: 1rem;
      justify-content: center;

      .label {
        display: none;
      }
      .icon {
        margin: 0;
      }
    }
  }
}

.admin-sidebar-header {
  height: 120px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  position: relative;

  .admin-logo {
    width: 180px;
    height: auto;
    transition: all 0.3s ease;
    filter: brightness(0) invert(1);
    opacity: 0.9;

    &:hover {
      opacity: 1;
      filter: brightness(0) invert(1)
        drop-shadow(0 0 8px rgba($secondary-color, 0.5));
    }
  }

  .sidebar-toggle {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.admin-navigation {
  padding: 1rem 0;

  &-item {
    width: 100%;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: rgba($light-color, 0.7);
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    .icon {
      margin-right: 1rem;
      font-size: 1.2rem;
      transition: all 0.3s ease;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 3px;
      background: $secondary-color;
      transform: translateX(-3px);
      transition: transform 0.3s ease;
    }

    &:hover {
      background: rgba($secondary-color, 0.1);
      color: $light-color;

      &::before {
        transform: translateX(0);
      }
    }

    &.active {
      background: rgba($primary-color, 0.15);
      color: $light-color;

      &::before {
        transform: translateX(0);
      }

      .icon {
        color: $secondary-color;
      }
    }
  }
}

.admin-main {
  margin-left: 280px;
  transition: all 0.3s ease;
  min-height: 100vh;
  position: relative;
  z-index: 1;

  &.blur {
    filter: blur(3px);
    pointer-events: none;
  }
}

.admin-header {
  height: 80px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  position: sticky;
  top: 0;
  z-index: 10;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: $light-color;
    margin: 0;
    position: relative;
    padding-left: 1rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 1.2em;
      background: $secondary-color;
      border-radius: 2px;
    }
  }
}

.admin-content {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.mobile-menu-toggle {
  display: none;
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1001;
  background: rgba($dark-color, 0.8);
  border: 1px solid rgba($secondary-color, 0.3);
  color: $light-color;
  padding: 0.75rem;
  border-radius: 12px;
  font-size: 1.25rem;
  cursor: pointer;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba($secondary-color, 0.5);
    background: rgba($dark-color, 0.9);
  }

  svg {
    color: $secondary-color;
  }
}

.mobile-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 1024px) {
  .admin-main {
    margin-left: $sidebar-collapsed-width;
  }

  .admin-sidebar {
    width: $sidebar-collapsed-width;

    &.collapsed {
      width: $sidebar-collapsed-width;
    }

    .admin-logo {
      width: 40px;
    }

    .admin-navigation-item .label {
      display: none;
    }

    .admin-navigation-item .icon {
      margin: 0 auto;
    }
  }
}

@media (max-width: 768px) {
  .admin-dashboard {
    grid-template-columns: 1fr;
  }

  .admin-main {
    margin-left: 0;
  }

  .mobile-menu-toggle {
    display: flex;
  }

  .admin-sidebar {
    transform: translateX(-100%);
    width: 250px;

    &.mobile-open {
      transform: translateX(0);
    }

    .admin-navigation-item .label {
      display: inline;
    }

    .admin-navigation-item .icon {
      margin-right: 1rem;
    }
  }

  .mobile-overlay {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.admin-sidebar.mobile-open {
  animation: slideIn 0.3s ease forwards;
}
