$breakpoints: (
  phone: 480px,
  tablet: 768px,
  tablet-landscape: 992px,
  laptop: 1200px,
  desktop: 1500px,
  desktop-large: 1920px,
  desktop-extra-large: 2560px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

@mixin grid($key) {
  display: grid;
  grid-template-columns: repeat($key, minmax(min-content, max-content));
}

@mixin grid-row($key) {
  display: grid;
  grid-template-rows: repeat($key, minmax(min-content, max-content));
}
@mixin grid-auto($type: "columns") {
  display: grid;
  grid-gap: 1rem; /* Optional, to add space between the child elements */

  @if $type == "columns" {
    grid-template-columns: repeat(auto-fill, minmax(min-content, max-content));
  } @else if $type == "rows" {
    grid-template-rows: repeat(auto-fill, minmax(min-content, max-content));
  }
}

$primary-color: #eb4abc;
$secondary-color: #cbf73f;
$dark-color: #1b1b1b;
$light-color: #f4f5ef;

.habit-settings {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  color: $light-color;

  .settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      inset: -1px;
      background: linear-gradient(
        45deg,
        transparent,
        rgba($primary-color, 0.2),
        rgba($secondary-color, 0.2),
        transparent
      );
      z-index: 0;
      animation: borderGlow 3s linear infinite;
    }

    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0;
      position: relative;
      z-index: 1;
      background: linear-gradient(
        45deg,
        $light-color,
        rgba($primary-color, 0.8)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .save-button {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.9rem 1.8rem;
      background: $primary-color;
      border: 1px solid rgba($secondary-color, 0.3);
      border-radius: 12px;
      color: $light-color;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      z-index: 1;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba($primary-color, 0.3),
          0 0 0 2px rgba($secondary-color, 0.1) inset;
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }

  .challenges-grid {
    display: grid;
    gap: 1.5rem;
  }

  .challenge-card {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    overflow: hidden;
    transition: all 0.3s ease;
    margin-bottom: 1.5rem;

    &:hover {
      transform: translateY(-2px);
      border-color: rgba($secondary-color, 0.2);
    }

    .challenge-header {
      cursor: pointer;
      overflow: hidden;
      border-radius: 16px 16px 0 0;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
      }

      .challenge-image {
        position: relative;
        height: 200px;
        background-size: cover;
        background-position: center;
        padding: 1.5rem;

        &::before {
          content: "";
          position: absolute;
          inset: 0;
          background: linear-gradient(
            to bottom,
            rgba($dark-color, 0.3),
            rgba($dark-color, 0.9)
          );
        }
      }

      .challenge-info {
        position: relative;
        z-index: 1;
        color: $light-color;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        h3 {
          margin: 0;
          font-size: 1.8rem;
          font-weight: 700;
          background: linear-gradient(
            45deg,
            $light-color,
            rgba($primary-color, 0.8)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        .challenge-date {
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          background: rgba($dark-color, 0.5);
          backdrop-filter: blur(4px);
          border-radius: 20px;
          border: 1px solid rgba($light-color, 0.1);
          font-size: 0.9rem;

          svg {
            color: $secondary-color;
          }
        }
      }
    }

    .weeks-container {
      background: rgba(255, 255, 255, 0.02);
      border-radius: 0 0 16px 16px;
      border-top: 1px solid rgba($light-color, 0.05);
      margin-top: -1px; // To overlap with header border
    }

    .week-section {
      background: rgba(255, 255, 255, 0.02);
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.07);
      overflow: hidden;

      &.active {
        border-color: rgba($secondary-color, 0.2);
      }

      .week-header {
        padding: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.04);
        }

        h4 {
          margin: 0;
          font-size: 1.1rem;
          color: $light-color;
        }

        .week-toggle {
          color: rgba($light-color, 0.6);
          transition: transform 0.3s ease;

          &.active {
            transform: rotate(180deg);
            color: $primary-color;
          }
        }
      }

      .week-content {
        padding: 1.5rem;
        display: grid;
        gap: 2rem;
        animation: slideDown 0.3s ease forwards;
        position: relative;
        isolation: isolate;
      }
    }

    .section {
      &-header {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-bottom: 1rem;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);

        svg {
          color: $secondary-color;
          font-size: 1.2rem;
        }

        h5 {
          margin: 0;
          font-size: 1rem;
          color: $light-color;
        }
      }

      .questions-grid {
        display: grid;
        gap: 1rem;
      }

      input {
        width: 100%;
        padding: 0.9rem 1rem;
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        color: $light-color;
        font-size: 0.95rem;
        transition: all 0.3s ease;

        &:focus {
          outline: none;
          border-color: rgba($primary-color, 0.5);
          box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
          background: rgba(255, 255, 255, 0.05);
        }

        &::placeholder {
          color: rgba($light-color, 0.4);
        }
      }

      .rich-text-wrapper {
        margin-top: 1rem;

        .quill {
          background: rgba($dark-color, 0.95);
          border-radius: 8px;
          overflow: hidden;

          .ql-toolbar {
            background: rgba(255, 255, 255, 0.05);
            border-color: rgba(255, 255, 255, 0.1);
            padding: 0.75rem;

            .ql-stroke {
              stroke: rgba($light-color, 0.7);
            }

            .ql-fill {
              fill: rgba($light-color, 0.7);
            }

            .ql-picker {
              color: rgba($light-color, 0.7);
            }
          }

          .ql-container {
            border-color: rgba(255, 255, 255, 0.1);
            background: rgba($dark-color, 0.95);
            color: $light-color;
          }

          .ql-editor {
            min-height: 120px;
            color: $light-color;

            &.ql-blank::before {
              color: rgba($light-color, 0.4);
            }

            p {
              color: $light-color;
            }
          }
        }
      }

      .link-control {
        display: grid;
        gap: 1rem;

        .toggle-button {
          padding: 0.75rem;
          background: rgba($primary-color, 0.1);
          border: 1px solid rgba($primary-color, 0.2);
          border-radius: 8px;
          color: $light-color;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s ease;

          &.active {
            background: $primary-color;
            border-color: rgba($secondary-color, 0.3);
          }

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba($primary-color, 0.2);
          }
        }
      }

      // Add specific spacing for action items
      &:last-child {
        margin-bottom: 0; // Remove bottom margin from last section
      }

      // Add specific styling for action items section
      .action-items {
        display: grid;
        gap: 1.5rem; // Consistent gap with other sections
      }

      input {
        margin-bottom: 1rem; // Add space between inputs
      }

      // Add specific spacing for action items inputs
      input[type="text"] {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }

      .rich-text-wrapper {
        margin-top: 1rem;
        margin-bottom: 1.5rem; // Add bottom margin to rich text editor
      }

      // Adjust spacing for the last rich text wrapper
      &:last-child .rich-text-wrapper {
        margin-bottom: 0;
      }
    }
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes borderGlow {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    padding: 1rem;

    .settings-header {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
      padding: 1.2rem;

      h2 {
        font-size: 1.5rem;
      }
    }

    .challenge-card {
      .challenge-header {
        flex-direction: column;
        gap: 1rem;
        text-align: center;

        .challenge-info {
          flex-direction: column;
          text-align: center;
          align-items: center;

          div {
            align-items: center;
          }

          .challenge-date {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    padding: 0.5rem;

    .challenge-card {
      .week-content {
        padding: 1rem;
      }
    }
  }
}

.ql-formats {
  button {
    border: 1px solid black !important;
    width: 3rem !important;
    margin-right: 0.5rem;
    padding: 0 !important;

    svg {
      width: 3rem;
    }
  }
}

.ql-toolbar {
}

.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.quill {
  padding-bottom: 4rem;
}
