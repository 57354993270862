$breakpoints: (
  phone: 480px,
  tablet: 768px,
  tablet-landscape: 992px,
  laptop: 1200px,
  desktop: 1500px,
  desktop-large: 1920px,
  desktop-extra-large: 2560px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

@mixin grid($key) {
  display: grid;
  grid-template-columns: repeat($key, minmax(min-content, max-content));
}

@mixin grid-row($key) {
  display: grid;
  grid-template-rows: repeat($key, minmax(min-content, max-content));
}
@mixin grid-auto($type: "columns") {
  display: grid;
  grid-gap: 1rem; /* Optional, to add space between the child elements */

  @if $type == "columns" {
    grid-template-columns: repeat(auto-fill, minmax(min-content, max-content));
  } @else if $type == "rows" {
    grid-template-rows: repeat(auto-fill, minmax(min-content, max-content));
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;

.createTeam {
  height: 100%;
  display: grid;
  padding: 1rem;
  padding-bottom: 4rem;
  margin-top: 5rem;

  @include mq(tablet) {
    width: 80%;
    justify-self: center;
  }

  &-container {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #d3d3d3;
    background: #f4f5ef;
    text-align: center;
    height: 9rem;
    &-title {
      font-weight: 700;
    }

    &-text {
      margin-top: 1rem;
      font-size: 0.75rem;
    }
  }

  &-title {
    text-align: center;
    font-weight: 800;
    margin-top: 1rem;
  }

  &-form {
    display: grid;
    grid-gap: 0.5rem;

    label {
      text-align: center;
    }
  }

  &-input {
    justify-self: center;
    border: 1px solid black;
    height: 2rem;
    width: 12rem;
    padding-left: 0.5rem;
  }

  &-button {
    justify-self: center;
    width: 80%;
    height: 2.75rem;
    background-color: $pink;
    border-radius: 1.5625rem;
    color: #fff;
    font-weight: 600;
    font-size: 0.8rem;
    margin-top: 1rem;

    @include mq(tablet) {
      width: 15rem;
    }
  }

  .profileApp {
    justify-self: center;
    width: 8rem;
  }

  &-avatar {
    justify-self: center;
    border-radius: 50%;
  }
}
