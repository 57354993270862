.share-icon {
  svg {
    display: inline; // Ensure SVG acts as an inline element
    vertical-align: middle; // Vertically align it with the text
    width: 1.25rem;
    height: auto; // Keep the aspect ratio of the SVG
    margin-right: 0.25rem;
    margin-left: 0.25rem; // Optional: Add a little space after the icon
    margin-bottom: 0.5rem;
  }
}
