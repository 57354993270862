//

$breakpoints: (
  small: 480px,
  bloody: 650px,
  midsmall: 800px,
  welldone: 1000px,
  medium: 1200px,
  large: 1800px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$grey: #d3d3d3;
$white: #f4f5ef;

.breathing {
  &-exercise {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &-outer {
      position: relative;
      display: flex;
      width: 10.063rem;
      height: 10.063rem;
      border-radius: 50%;
      background-color: white;
      border: 1px solid $grey;
      justify-content: center;
      align-items: center;
      justify-self: center;
    }

    &-center {
      width: 2.786rem;
      height: 2.786rem;
      border-radius: 50%;
      background-color: $green;

      &-animation {
        animation: breathing infinite 10s linear;
      }
    }

    &-in,
    &-out {
      position: absolute;
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;

      width: 6rem;
      height: 1rem;
    }

    &-in-animation {
      animation: breathingIn infinite 10s;
    }

    &-out-animation {
      animation: breathingOut infinite 10s;
    }
  }
}

@keyframes breathing {
  0% {
    width: 2.786rem;
    height: 2.786rem;
  }
  50% {
    width: 10.063rem;
    height: 10.063rem;
  }
  100% {
    width: 2.786rem;
    height: 2.786rem;
  }
}

@keyframes breathingIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes breathingOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
