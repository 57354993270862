$primary-color: #eb4abc;
$secondary-color: #cbf73f;
$dark-color: #1b1b1b;
$light-color: #f4f5ef;

.blog-settings {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  color: $light-color;

  .settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      inset: -1px;
      background: linear-gradient(
        45deg,
        transparent,
        rgba($primary-color, 0.2),
        rgba($secondary-color, 0.2),
        transparent
      );
      z-index: 0;
      animation: borderGlow 3s linear infinite;
    }

    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0;
      position: relative;
      z-index: 1;
      background: linear-gradient(
        45deg,
        $light-color,
        rgba($primary-color, 0.8)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .save-button {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.9rem 1.8rem;
      background: $primary-color;
      border: 1px solid rgba($secondary-color, 0.3);
      border-radius: 12px;
      color: $light-color;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      z-index: 1;

      svg {
        font-size: 1.2rem;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba($primary-color, 0.3),
          0 0 0 2px rgba($secondary-color, 0.1) inset;
      }
    }
  }

  // Blog Categories Section
  .blog-settings-category {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    &-title {
      font-size: 1.4rem;
      color: $light-color;

      padding-bottom: 0.75rem;
      border-bottom: 1px solid rgba($secondary-color, 0.2);
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 50px;
        height: 2px;
        background: $secondary-color;
      }
    }
  }

  .blog-settings-blogs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }

  .blog-settings-blog {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    overflow: hidden;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      border-color: rgba($secondary-color, 0.3);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    }

    &-image {
      width: 100%;
      height: 200px;
      overflow: hidden;
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    &-content {
      padding: 1.5rem;

      &-title {
        font-size: 1.2rem;
        color: $light-color;
      }

      &-text {
        color: rgba($light-color, 0.7);
        margin-bottom: 0.75rem;
        font-size: 0.9rem;
      }

      &-link {
        background: rgba($secondary-color, 0.1);
        border: 1px solid rgba($secondary-color, 0.3);
        color: $secondary-color;
        padding: 0.6rem 1rem;
        border-radius: 8px;
        margin-right: 0.5rem;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 0.9rem;

        &:hover {
          background: rgba($secondary-color, 0.2);
          transform: translateY(-2px);
        }

        &:last-child {
          background: rgba($primary-color, 0.1);
          border-color: rgba($primary-color, 0.3);
          color: $primary-color;

          &:hover {
            background: rgba($primary-color, 0.2);
          }
        }
      }
    }
  }

  @keyframes borderGlow {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .settings-header {
      flex-direction: column;
      gap: 1rem;
      text-align: center;

      h2 {
        font-size: 1.5rem;
      }
    }

    .blog-settings-blogs {
      grid-template-columns: 1fr;
    }
  }
}

.category-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 0.75rem 0;

  .existing-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    button {
      background: rgba($primary-color, 0.05);
      border: 1px solid rgba($primary-color, 0.3);
      color: $primary-color;
      padding: 0.35rem 0.75rem;
      border-radius: 15px;
      font-size: 0.8rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: $primary-color;
        color: white;
        transform: translateY(-1px);
        box-shadow: 0 2px 8px rgba($primary-color, 0.2);
      }
    }
  }
}

.form-group {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  margin: 1rem 0;

  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    user-select: none;
    font-size: 0.85rem;
    color: $light-color;
    transition: all 0.3s ease;
    flex: 1;

    &:hover {
      color: $primary-color;
    }

    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      border: 1.5px solid $primary-color;
      border-radius: 4px;
      outline: none;
      cursor: pointer;
      position: relative;
      transition: all 0.3s ease;

      &:checked {
        background-color: $primary-color;
        border-color: $primary-color;

        &::after {
          content: "✓";
          position: absolute;
          color: white;
          font-size: 12px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:hover {
        border-color: lighten($primary-color, 10%);
        box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;

    label {
      font-size: 0.8rem;
      width: 100%;
    }
  }
}

.date-section {
  .label {
    color: $light-color;
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
  }

  @media (max-width: 768px) {
    .blog-settings-form-input {
      width: 100%;
      max-width: none;
    }
  }
}

@media (max-width: 768px) {
  .category-container {
    .existing-categories {
      button {
        padding: 0.25rem 0.6rem;
        font-size: 0.75rem;
      }
    }
  }
}
