.orders-text {
  text-align: center;
}

.orders-button {
  border: 1px solid black;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  margin-top: 0.5rem;
}

.orders-pdf {
  margin-top: 1rem;
}
