$breakpoints: (
  phone: 480px,
  tablet: 768px,
  tablet-landscape: 992px,
  laptop: 1200px,
  desktop: 1500px,
  desktop-large: 1920px,
  desktop-extra-large: 2560px,
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

@mixin grid($key) {
  display: grid;
  grid-template-columns: repeat($key, minmax(min-content, max-content));
}

@mixin grid-row($key) {
  display: grid;
  grid-template-rows: repeat($key, minmax(min-content, max-content));
}
@mixin grid-auto($type: "columns") {
  display: grid;
  grid-gap: 1rem; /* Optional, to add space between the child elements */

  @if $type == "columns" {
    grid-template-columns: repeat(auto-fill, minmax(min-content, max-content));
  } @else if $type == "rows" {
    grid-template-rows: repeat(auto-fill, minmax(min-content, max-content));
  }
}

$green: #cbf73f;
$pink: #eb4abc;
$dark: #1b1b1b;
$brown: #d3d3d3;
$white: #f4f5ef;

.teamInvite {
  @include grid-row(4);
  justify-content: center;
  text-align: center;
  grid-gap: 2rem;
  padding: 4rem;
  margin-top: 4rem;

  h1 {
    font-weight: 800;
  }

  button {
    width: 13rem;
    height: 3rem;
    border-radius: 1rem;
    border: none;
    justify-self: center;
    font-weight: 600;
  }

  &-yes {
    color: $white;
    background-color: $pink;
  }

  &-no {
    background-color: $white;
  }
}
