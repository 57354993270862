.custom-checkbox {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox-mark {
      background-color: #cbf73f;
      border-color: #cbf73f;

      &:after {
        display: block;
      }
    }

    &:checked ~ .checkbox-label {
      color: rgba(0, 0, 0, 0.9);
      font-weight: 700;
    }
  }

  .checkbox-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid #eb4abc;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 10px rgba(235, 74, 188, 0.1);

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:hover {
    .checkbox-mark {
      border-color: #eb4abc;
      background-color: rgba(235, 74, 188, 0.1);
      box-shadow: 0 0 15px rgba(235, 74, 188, 0.2);
      transform: scale(1.05);
    }
  }

  .checkbox-label {
    font-size: 0.875rem;
    line-height: 1.4;
    padding-top: 2px;
    transition: all 0.2s ease-in-out;
    color: #000000;
  }

  &:hover .checkbox-label {
    color: #000;
    font-weight: 600;
  }
}
